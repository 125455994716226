<template>
    <div class="vip-renewal">
        <div style="font-size: 20px;color: #333333;margin:0 0 10px 10px; font-weight: 600">请选择需要续费的子账号年费标准：</div>
        <div style="display: flex; flex-wrap: wrap">
            <div v-for="(item,index) in [num, num, num]" :key="index">
                <div @click="SetMeal(index)" class="hand op money-box" :class="index == zIndex?'select-box':''">
                      <div style="text-align: center; font-size: 20px;margin:20px 0 0 0;">购买{{index + 1}}年</div>
                      <div style="text-align: center; font-size: 30px;font-weight: bold;margin:20px 0 0 0;">￥{{utils.keepTwoDecimalFull(item.price * (index + 1))}}</div>
                  <div class="select-icon" v-if="index == zIndex"><i class="el-icon-check tr-icon"></i></div>
                </div>
            </div>
        </div>

        <el-divider></el-divider>

        <div  style="font-size: 20px;color: #444444;margin:20px 0 20px 10px">请确认您的订单详情：为百工宝账号
            <span style="color:#EE3F4D">{{user.nickName}}</span>需支付
            <span style="color:#EE3F4D">{{payAmt}}元</span>，请完成支付操作
        </div>

        <div style="width: 100%;height: 35px;">
            <div @click="payOrderNow" style="width: 80px;line-height: 35px;background: red;text-align: center;float: right;color: #FFFFFF;border-radius: 12px;cursor: pointer;">立即下单</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SubRenewal",
        components: {},
        data() {
            return {
                num: {
                    number: '',
                    price: '',
                    unitPrice: ''
                },
                subList: [],
                zIndex: '',
                orderNo:'',
                payAmt:0,
                zYear: 0,
                orderType: '7',
                sign:'',
            }
        },
        props: {
            subaccount: {
                type: Number,
                default() {
                    return 0
                }
            }
        },

        computed: {
            user() {
                return this.$store.state.user;
            },
        },

        created() {
            var that = this;
            //预支付订单监听
            this.$EventBus.$EventBus.$off("prePayOrder");
            this.$EventBus.$on("prePayOrder", (data) => {
                this.$nextTick(() => {
                    if (data.code == 100) {
                        that.bankPay(data.toPay);
                    }
                });
            });
        },

        mounted() {
            this.getSubList();
        },

        methods: {
            //立即下单
            payOrderNow(){
                var that = this;
                //组装微信、支付宝首购需要的数据
                let parameter = {};
                parameter.year = that.zYear;
                parameter.price = that.utils.keepTwoDecimalFull(that.payAmt);

                let params = that.$store.state.payParams;
                params.type = 1;
                params.payAmt = that.payAmt;
                params.orderType = that.orderType;
                params.payName = "";
                params.payParams = parameter;
                that.sign = that.utils.random_string(6);
                that.newApi.addAndSavePrePayOrder({type:1,payParams:JSON.stringify(params),sign:that.sign}).then(() => {
                    that.b('/newAdaPay/?sign='+that.sign);
                })
            },

            // 获取套餐详情
            getAllInfo() {
                this.newApi.packageInfo({type: 3}).then(res => {
                    this.num.number = this.subaccount;
                    this.num.price = res.data.price * this.subaccount;
                    this.num.unitPrice = res.data.unitPrice;
                    this.SetMeal(0)
                })
            },

            // 获取子账号价格列表
            getSubList() {
                this.newApi.packageSubList().then(res => {
                    this.subList = res.data;
                    this.getAllInfo();
                })
            },

            //选择哪种套餐
            SetMeal(index){
                var that = this;
                that.zIndex = index;
                that.zYear = index + 1;
                that.payAmt = (this.num.price * 100)  * (index + 1) /100;
            },

            bankPay(data){
                var that = this;
                let parameter = {};
                parameter.payType = 1;
                parameter.year = that.zYear;
                parameter.price = that.utils.keepTwoDecimalFull(that.payAmt);
                parameter.orderNo = data.orderNo;
                //判断是对公还是公绑私
                if(data.activeName == 'corporate'){
                    that.newApi.renewalSubDetermine(parameter).then(() => {})
                }
            },
        }
    }
</script>

<style scoped>
.vip-renewal .money-box {
  width: 200px;
  height: 140px;
  border-radius: 10px;
  border: 1px solid #CED0D4;
  margin: 10px 10px 0 0;
  position: relative
}

.vip-renewal .select-box {
  background: url("~@/pages/user/imgs/moneyOrgan.png");
  color: #0052C1;
}

.vip-renewal .select-icon {
  color: #FFFFFF;
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  right: 0;
  border: 18px solid #fff;
  border-bottom-right-radius: 10px;
  border-bottom-color: #0052C1;
  border-right-color: #0052C1;
  border-top-color:transparent ;
  border-left-color: transparent;
}

.vip-renewal .tr-icon {
  color: #FFFFFF;
  position:absolute;
  font-weight: 600;
}
</style>
