<template>
    <div class="add-sub">
        <div style="margin-bottom: 20px">
            <div style="margin-bottom: 20px">
                <span style="margin-right: 30px; font-size: 18px; font-weight: 600">当前子账号使用情况：<span style="color: #2970FF">共{{subs.length}}个</span></span>
                <span style="margin-right: 30px; color: #5BC130;"><i class="el-icon-circle-check"></i> 已激活</span>
                <span style="margin-right: 30px; color: #666666;"><i class="el-icon-circle-close"></i> 未激活</span>
                <span style="margin-right: 30px; color: #FF9800"><i class="el-icon-warning-outline"></i> 已冻结</span>
            </div>
          <div style="display: flex; flex-wrap: wrap">
            <div v-for="(item, index) in subs" :key="index">
              <div v-if="item.info.status === 0" class="btn-type" style="color: #FFFFFF; background-color: #5BC130">{{item.nickName}} <i class="el-icon-circle-check" style="margin-left: 6px"></i></div>
              <div v-if="item.info.status === 1" class="btn-type" style="background-color: #E8E8E8">{{item.nickName}} <i class="el-icon-circle-close" style="margin-left: 6px"></i></div>
              <div v-if="item.info.status === 2" class="btn-type" style="color: #FF9800; border: 1px #FF9800 solid">{{item.nickName}} <i class="el-icon-warning-outline" style="margin-left: 6px"></i></div>
            </div>
          </div>
        </div>

        <div style="margin-bottom: 20px">
            <div style="font-size: 18px; font-weight: 600; margin-bottom: 10px">请选择需要扩容的子账号数：<span style="font-size: 16px; color: #999999">（额外增加的子账号直接加在原有数量上）</span></div>
            <div>
                <el-radio-group v-model="num"  @input="getSubPrice">
                    <el-radio style="margin-bottom: 10px" v-for="(item, index) in subList" :key="index" :label="item.id">{{item.number}}</el-radio>
                </el-radio-group>
            </div>
        </div>

        <div style="display: flex; justify-content: space-between">
            <div style="margin-right: 20px; color: #999999; width: 66%">
                说明：在有效期内添加子账号仅需补交差价，随子账号有效期计算
                子账号到期后按照最后一次购买子账号数量的原价格一年计算
            </div>
            <div style="font-weight: 600; margin-top: 10px">
                <span style="font-size: 20px;">合计：</span>
                <span style="font-size: 30px; color: #EE3F4D">￥{{payAmt}}</span>
            </div>
        </div>

        <el-divider></el-divider>

        <div  style="font-size: 20px;color: #444444;margin:20px 0 20px 10px">请确认您的订单详情：为百工宝账号
            <span style="color:#EE3F4D">{{user.nickName}}</span>需支付
            <span style="color:#EE3F4D">{{payAmt}}元</span>，请完成支付操作
        </div>

        <div style="width: 100%;height: 35px;">
            <div @click="payOrderNow" style="width: 80px;line-height: 35px;background: red;text-align: center;float: right;color: #FFFFFF;border-radius: 12px;cursor: pointer;">立即下单</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "addSub",
        components: {},
        data() {
            return {
                num: 1,
                subList: [],
                zIndex: '',
                zYear: 0,
                payAmt: 0,
                orderType: '8',
                subs: [],
                sign:'',
            }
        },
        computed: {
            user() {
                return this.$store.state.user;
            },
        },
        created() {
            var that = this;
            //预支付订单监听
            this.$EventBus.$EventBus.$off("prePayOrder");
            this.$EventBus.$on("prePayOrder", (data) => {
                this.$nextTick(() => {
                    if (data.code == 100) {
                        that.bankPay(data.toPay);
                    }
                });
            });
        },

        mounted() {
            this.getSubList();
            this.getSubInfo();

        },

        methods: {
            //立即下单
            payOrderNow(){
                var that = this;
                //组装微信、支付宝首购需要的数据
                let parameter = {};
                parameter.payType = 1;
                parameter.subYear = that.zYear;
                parameter.count = that.subList[that.num -1].number;
                parameter.price = that.utils.keepTwoDecimalFull(that.payAmt);

                let params = that.$store.state.payParams;
                params.type = 1;
                params.payAmt = that.payAmt;
                params.orderType = that.orderType;
                params.payName = "";
                params.payParams = parameter;

                that.sign = that.utils.random_string(6);
                that.newApi.addAndSavePrePayOrder({type:1,payParams:JSON.stringify(params),sign:that.sign}).then(() => {
                    that.b('/newAdaPay/?sign='+that.sign);
                })
            },

            // 获取子账号差价
            getSubPrice(count) {
                this.newApi.getEnlargeSubPrice({count: this.subList[this.num].number}).then(res => {
                    this.payAmt = res.data.price
                })
            },


            // 获取子账号列表
            getSubInfo() {
                this.newApi.getSubAccountList().then(res => {
                    for (const item of res.data.admin) {
                        this.subs.push(item)
                    }
                    for (const item of res.data.ordinary) {
                        this.subs.push(item)
                    }
                })
            },

            //选择哪种套餐
            SetMeal(index){
                var that = this;
                that.zIndex = index;
                that.zYear = index + 1;
                if (this.num.price) {
                    that.payAmt = (this.num.price * 100)  * (index + 1) /100;
                }
            },

            // 获取子账号价格列表
            getSubList() {
                this.newApi.packageSubList().then(res => {
                    this.subList = res.data;
                    this.payAmt = res.data[0].price;
                    this.SetMeal(0)
                })
            },


            bankPay(data){
                var that = this;
                let parameter = {};
                parameter.payType = 1;
                parameter.count = that.subList[that.num - 1].number;
                parameter.price = that.utils.keepTwoDecimalFull(that.payAmt);
                parameter.orderNo = data.orderNo;

                //判断是对公还是公绑私
                if(data.activeName == 'corporate'){
                    that.newApi.enlargeSubDetermine(parameter).then(() => {})
                }
            },
        }
    }
</script>

<style scoped>
.add-sub .btn-type {
  height: 30px;
  line-height: 30px;
  padding: 0 6px;
  margin: 0 6px 6px 0;
  font-size: 16px;
  border-radius: 5px;
  text-align: center;
}
</style>
