<template>
  <div class="pay-center">
    <!--个人-->
    <div v-if="user.userType === 1" style="display: flex" class="pay-body">
      <div style="width: 278px; background: #FFFFFF;border-radius: 10px 10px 10px 10px;border: 1px solid #CCCCCC;float: left; padding-bottom: 20px">
        <div class="pay-back"
             style="width: 278px;height: 107px; border-radius: 10px 10px 0 0; text-align: center">
          <div style="font-size: 20px;color: #FFFFFF; padding-top: 20px">个人VIP</div>
          <div style="font-size: 18px;color: #FFFFFF;margin-top:10px">会员用户权益</div>
        </div>
        <div class="text-left" v-for="(item, index) in memberList" :key="index">{{item}}</div>

        <div class="hand op" @click="onChange('user')" style="width: 170px; margin-left: 44px; font-size: 18px;line-height: 42px;text-align: center;border-radius: 30px; border: 1px #1a1a1a solid;margin-top: 30px;">查看更多</div>
      </div>
      <div style="width: 100%">
        <div style="margin-left: 30px">
          <MyRenewal v-if="spaceEndTime != null" :info="userWalletInfo"></MyRenewal>
          <annual-fee v-else></annual-fee>
        </div>
      </div>
    </div>
    <!--机构-->
    <div v-if="user.userType === 2" style="display: flex" class="pay-body">
      <div style="width: 272px; background: #FFFFFF;border-radius: 10px 10px 10px 10px;border: 1px solid #CCCCCC;float: left;padding-bottom: 20px">
        <div class="organ-title"
             style="width: 272px;height: 107px; border-radius: 10px 10px 0 0; text-align: center">
          <div style="font-size: 20px;color: #FFFFFF; padding-top: 20px">机构年费版</div>
          <div style="font-size: 18px;color: #FFFFFF;margin-top:10px">会员用户权益</div>
        </div>
        <div class="text-left-organ">1. 可以开设机构店铺，方便上传展示您的产业和服务，提供您企业业务的线上服务接口。</div>
        <div class="text-left-organ">2. 可以通过子账号来管理您的业务，满足各种情况下的业务发展需求，真正解决企业业务数字化需求。</div>
        <div class="text-left-organ">3. 所有子账号可以均可获得大部分个人账号的功能和权益，但是无需专门的认证，效率极高。</div>
        <div class="text-left-organ">4. 对子账号的权限进行设置和管理，可以让不同的人维护同一个子账号，保护您企业的重要的客户信息和业务资源的安全。</div>
        <div class="text-left-organ">5. 提供统一的包括子账号在内的空间管理，并可以随时扩展。</div>
        <div class="hand op" @click="onChange('user')" style="width: 170px; margin-left: 44px; font-size: 18px;line-height: 42px;text-align: center;border-radius: 30px; border: 1px #1a1a1a solid;margin-top: 30px;">查看更多</div>
      </div>
      <div style="width: 100%">
        <div style="margin-left: 30px">
          <OrganRenewal v-if="spaceEndTime != null" :info="userWalletInfo"></OrganRenewal>
          <OrganFirst v-else></OrganFirst>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyRenewal from "@/pages/user/components/MyRenewal.vue";
import AnnualFee from "@/pages/user/components/annualFee.vue";
import OrganRenewal from "@/pages/user/components/organ/OrganRenewal.vue";
import OrganFirst from "@/pages/user/components/organ/OrganFirst.vue";

export default {
  name: "PayCenter",
  components: {OrganFirst, OrganRenewal, AnnualFee, MyRenewal},
  data() {
    return {
      memberList: [
        '1.开通会员后，你的改昵称次数上限立即提升；会员过期后，你的改昵称次数上限会回到普通用户状态',
        '2.年费会员(VIP)可置顶1条微博',
        '3. 开通会员后，您的关注上限将',
        '4. 成为会员可关注1000人',
        '5. 开通会员，可创建项目ROOM10个、创建兴趣ROOM5个、创建经营ROOM2个/年。',
        '6. 百工宝会员可自荐自己所发文章、动态、资料等信息，让好内容被TA看见',
        '7. 开通会员，并创建店铺并完善店铺资料及产品/服务，可获得首页推荐机会3次/年',
        '8. 百工宝年费会员(VIP)拨打4000-接入语音人工服务后享受专属服务，年费会员过期后，专属客服服务失效。',
        '9. 「健康保镖」是7X24小时在线的社区治理机器人，如果出现言语侮辱等，平台会自动拦截有害信息',
        '10. 可以获得各类专家提供的专业咨询服务，专家团队数量和专家数量均不受限制。'
      ],
      spaceEndTime:null,
        userWalletInfo:this.info,
    }
  },

  props: {
    onChange: {
      type:Function,
      default() {
        return function(){}
      }
    },
      info: {
          type:Object,
          default() {
              return {}
          }
      },
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },



    mounted() {
        var that = this;
        that.spaceEndTime = this.userWalletInfo.spaceEndTime;

        // 先销毁事件，防止重复触发
        this.$EventBus.$EventBus.$off("payMsg");
        this.$EventBus.$on("payMsg", (data) => {
            this.$nextTick(() => {
                if(data.code == 100){
                    console.log("PayCenter");
                    setTimeout(function (){
                        that.getUserWalletInfo();
                        that.onChange('pay');
                    },1000)
                }
            });
        });
    },

    methods: {
        //获取钱包的用户信息
        getUserWalletInfo() {
            var that = this;
            that.newApi.getUserWalletInfo().then(res => {
                if (res.isSuccess == 1) {
                    that.userWalletInfo = res.data;
                    that.spaceEndTime = that.userWalletInfo.spaceEndTime;
                }
            });
        },
    },
}
</script>

<style scoped>
.pay-center {
  width: 100%;
}

.pay-center .pay-body {
  padding: 20px 30px;
  background-color: #FFFFFF;
  margin-bottom: 10px;
  overflow: hidden;
}

.pay-center .pay-back {
  background-image: url("../../../../assets/imgs/index/Annualfee.png");
}

.pay-center .organ-title {
  background-image: url("./../../imgs/blue.png");
}

.pay-center .text-left {
  font-size: 16px;
  color: #018679;
  margin: 20px 24px 0 24px;
}

.pay-center .text-left-organ {
  font-size: 16px;
  color: #0056C1;
  margin: 20px 24px 0 24px;
}
</style>
