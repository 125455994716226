<template>
  <div class="equity">
    <el-table
        :data="user.userType === 1 ? tableData : organData"
        border
        style="width: 100%; border-radius: 10px; margin-bottom: 10px">
      <el-table-column prop="title" label="用户权益" align="center" width="160px"></el-table-column>
      <el-table-column prop="normal" :label="user.userType === 1 ? '普通用户可享受' : '普通机构用户可享受'" align="center" width="420px">
        <template slot-scope="scope">
          <div class="wrap-text" v-for="(item, index) in scope.row.normal" :key="index">{{ item }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="experience" :label="user.userType === 1 ? '体验官优惠政策（会员权益）' : '机构体验官优惠政策（会员权益）'" align="center" width="460px">
        <template slot-scope="scope">
          <div class="wrap-text" style="color: #7B5419" v-for="(item, index) in scope.row.experience" :key="index">{{ item }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="invitation" :label="user.userType === 1 ? '半邀请制优惠政策' : '机构半邀请制优惠政策'" align="center">
        <template slot-scope="scope">
          <div class="wrap-text" v-for="(item, index) in scope.row.invitation" :key="index">{{ item }}</div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name:'UserEquity',
  components: {
  },
  data() {
    return {
      tableData: [
        {
          title: '赠送',
          normal: ['无优惠政策'],
          experience: [
              '1、6个月VIP半年卡',
              '2、续年卡会员享8折优惠',
              '3、知识仓库资料限免（1个月）'],
          invitation: ['1、3个月VIP季卡',
              '2、续年卡会员享8.5折优惠',
              '3、知识仓库资料7折（1个月）']
        },
        {
          title: '平台',
          normal: ['1、免费获取1G储存空间',
              '2、发布（需求信息、问题、资' +
              '料）、写（文章、问答）、报名' +
              '需求信息、开通SHOP、创建' +
              'ROOM和话题获取经验值，成' +
              '功成为大V时，可开【打赏】获' +
              '取佣金',
              '3、内容分析'],
          experience: ['1、专属标识',
              '2、专属客服',
              '3、优先推荐',
              '4、免费获取2G储存空间',
              '5、赞赏功能',
              '6、评论置顶',
              '7、内容分析',
              '8、健康保镖'],
          invitation: ['1、免费获取1G储存空间',
              '2、发布（需求信息、问题、资' +
              '料）、写（文章、问答）、报名' +
              '需求信息、开通SHOP、创建' +
              'ROOM和话题获取经验值，成' +
              '功成为大V时，可开【打赏】获' +
              '取佣金',
              '3、内容分析'],
        },
        {
          title: '朋友圈',
          normal: ['通过写文章、问答、创建话题、报名并参与活动获取经验值，到了规定等级后，可开【打赏】功能获取佣金'],
          experience: ['1、动态置顶',
          '2、关注上限',
          '3、内容自荐（3次/月',
          '4、通过写文章、问答、创建话题、报名并参与活动获取经验值，到了规定等级后，可开【打赏】功能获取佣金'],
          invitation: ['通过写文章、问答、创建话题、报名并参与活动获取经验值，到了规定等级后，可开【打赏】功能获取佣金'],
        },
        {
          title: 'ROOM',
          normal: ['1、创建项目ROOM3个',
          '2、创建兴趣ROOM3个',
          '3、创建经营ROOM1个/年'],
          experience: ['1、创建项目ROOM10个',
          '2、创建兴趣ROOM5个',
          '3、创建经营ROOM2个/年'],
          invitation: ['1、创建项目ROOM3个',
          '2、创建兴趣ROOM3个',
          '3、创建经营ROOM1个/年'],
        },
        {
          title: 'SHOP',
          normal: [],
          experience: ['创建店铺并完善店铺资料及产品/服务，可获得首页推荐机会3次'],
          invitation: [],
        },
        {
          title: '知识仓库',
          normal: ['1、上传资料获取经验值',
          '2、免费下载5个资料'],
          experience: ['1、上传资料获取经验值',
          '2、免费下载10个资料',
          '3、视频收益（发布5条以上的原创视频后，才可开通）'],
          invitation: ['1、上传资料获取经验值',
          '2、免费下载5个资料'],
        },
        {
          title: '专家连线',
          normal: [],
          experience: ['认证成为专家，并开通专家连线服务后，可获得首页推荐机会3次'],
          invitation: [],
        }
      ],
      organData: [
        {
          title: '赠送',
          normal: ['无优惠政策'],
          experience: [
            '1、3个月VIP半年卡',
            ' 2、续年卡会员享9折优惠 ',
            '3、知识仓库资料限免（1个月）'],
          invitation: ['1、1个月VIP季卡',
            '2、续年卡会员享9.5折优惠',]
        },
        {
          title: '平台',
          normal: ['1、免费获取1G储存空间',
            '2、内容分析'],
          experience: ['1、专属标识',
            '2、专属客服',
            '3、昵称唯一保护',
            '4、搜索昵称置顶',
            '5、优先推荐',
            '6、免费获取2G储存空间',
            '7、赞赏功能',
            '8、评论置顶',
            '9、内容分析',
            '10、健康保镖',
            '11、宣发不限流'],
          invitation: ['1、免费获取1G储存空间',
            '2、内容分析'],
        },
        {
          title: '朋友圈',
          normal: [],
          experience: ['1、动态置顶',
            '2、关注上限',
            '3、内容自荐（3次/月',
            '4、子账号加好友，上限20人'],
          invitation: [],
        },
        {
          title: 'ROOM',
          normal: ['1、创建项目ROOM3个',
            '2、创建兴趣ROOM3个',
            '3、创建经营ROOM1个/年',
            '4、创建机构ROOM1个/年',
            '5、子账户5个',
            '6、标准模板文档'],
          experience: ['1、创建项目ROOM10个',
            '2、创建兴趣ROOM5个',
            '3、创建经营ROOM2个/年',
            '4、创建机构ROOM1个/年',
            '5、子账户20个',
            '6、解锁更多标准模板文档'],
          invitation: ['1、创建项目ROOM3个',
            '2、创建兴趣ROOM3个',
            '3、创建经营ROOM1个/年',
            '4、创建机构ROOM1个/年',
            '5、子账户5个',
            '6、标准模板文档'],
        },
        {
          title: 'SHOP',
          normal: ['可创建店铺并完善店铺资料，上传产品或服务'],
          experience: [' 1、创建店铺并完善店铺资料，上传产品或服务，可获得首页推荐机会3次',
        '2、项目成功交易后，可在【百工宝】公众号上发布案例进行宣传'],
          invitation: ['可创建店铺并完善店铺资料，上传产品或服务'],
        },
        {
          title: '知识仓库',
          normal: ['1、上传资料获取经验值',
            '2、免费下载3个资料'],
          experience: ['1、上传资料获取经验值',
            '2、免费下载10个资料',
            '3、视频收益（发布5条以上的原创视频后，才可开通）'],
          invitation: ['1、上传资料获取经验值',
            '2、免费下载3个资料'],
        },
        {
          title: '专家连线',
          normal: ['1、认证成为专家团队',
          '2、专家团队成员5名'],
          experience: ['1、认证成为专家团队，并开通专家连线服务后，可获得首页推荐机会3次', '2、专家团队成员20名'],
          invitation: ['1、认证成为专家团队',
            '2、专家团队成员5名'],
        }
      ]
    }
  },
  props:{
  },
  computed:{
    user() {
      return this.$store.state.user
    },
  },
  created(){
  },
  mounted() {
  },
  watch: {
  },
  methods:{

  },


}
</script>

<style scoped>
.equity {
  background-color: #FFFFFF;
  margin-bottom: 10px;
  padding: 30px 40px;
}

/deep/ .has-gutter {
  height: 70px;
  box-shadow: 0 1px 10px 1px rgba(0,0,0,0.16);
}

/deep/ .cell {
  font-size: 16px;
}
</style>
