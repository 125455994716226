<template>
    <div class="vip-renewal" style="width: 100%">
        <div style="font-size: 20px;color: #333333;margin:0 0 10px 10px; font-weight: 600">请选择续费时长：</div>
        <div style="display: flex; ">
          <div v-for="(item,index) in vipList" :key="index">
            <div @click="SetMeal(index)" class="hand op money-box" :class="index == zIndex?'select-box':''">
              <div style="text-align: center; font-size: 20px;margin:20px 0 0 0;">购买{{item.month}}月</div>
              <div style="text-align: center; font-size: 30px;font-weight: bold;margin:20px 0 0 0;">￥{{item.price}}</div>
              <div class="select-icon" v-if="index == zIndex"><i class="el-icon-check tr-icon"></i></div>
            </div>
          </div>
        </div>

        <el-divider></el-divider>
        <div style="width: 100%;height: 35px;">
            <div @click="payOrderNow" style="width: 80px;line-height: 35px;background: red;text-align: center;float: right;color: #FFFFFF;border-radius: 10px;cursor: pointer;">立即下单</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "VipRenewal",
        components: {},
        data() {
            return {
                vipList: [],
                zIndex: '',
                orderNo:'',
                payAmt:0,
                zMonth: 0,
                wxOrderType: '2',
                orderType: '2',
                sign:'',
            }
        },

        computed: {
            user() {
                return this.$store.state.user;
            },
        },
        created() {
            var that = this;
            //预支付订单监听
            this.$EventBus.$EventBus.$off("prePayOrder");
            this.$EventBus.$on("prePayOrder", (data) => {
                this.$nextTick(() => {
                    if (data.code == 100) {
                        that.bankPay(data.toPay);
                    }
                });
            });
        },

        mounted() {
            this.getVipList();
        },

        methods: {
            //立即下单
             payOrderNow(){
                var that = this;
                let params = that.$store.state.payParams;
                params.type = 1;
                params.payAmt = that.payAmt;
                params.orderType = that.orderType;
                params.payName = "";
                that.sign = that.utils.random_string(6);
                that.newApi.addAndSavePrePayOrder({type:1,payParams:JSON.stringify(params),sign:that.sign}).then(() => {
                    that.b('/newAdaPay/?sign='+that.sign);
                })

            },

            //获取年费列表
            getVipList(){
                var that = this;
                that.newApi.packageVipList().then((res) => {
                    if(res.isSuccess == 1){
                        that.vipList = res.data;
                        this.SetMeal(0);
                    }
                })
            },

            //选择哪种套餐
            SetMeal(index){
                var that = this;
                that.zIndex = index;
                that.zMonth = that.vipList[index].month;
                that.payAmt = that.vipList[index].price;
            },


            bankPay(data){
                var that = this;
                var params = {};
                params.orderNo = data.orderNo;
                params.code = data.code;
                params.payPwd = data.payPwd;

                let parameter = {};
                parameter.payType = data.payChannel;
                parameter.month = that.zMonth;
                parameter.price = that.utils.keepTwoDecimalFull(that.payAmt);
                parameter.orderNo = data.orderNo;

                //判断是对公还是公绑私
                if(data.activeName != 'corporate'){
                    that.firstBuy(parameter, params);
                }
            },

            // 会员续费接口
            firstBuy(parameter, params) {
                var that = this;
                that.newApi.renewalVipDetermine(parameter).then((res) => {
                    if(res.isSuccess == 1){
                        that.newApi.alpplyBankCardPayDetermine(params).then(() => {})
                    }
                })
            },
        }
    }
</script>

<style scoped>
.vip-renewal .money-box {
  width: 200px;
  height: 140px;
  border-radius: 10px;
  border: 1px solid #CED0D4;
  margin: 10px 10px 0 0;
  position: relative
}

.vip-renewal .select-box {
  background: url("~@/pages/user/imgs/moneySelect.png");
  color: #018478;
}

.vip-renewal .select-icon {
  color: #FFFFFF;
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  right: 0;
  border: 18px solid #fff;
  border-bottom-right-radius: 10px;
  border-bottom-color: #009675;
  border-right-color: #009675;
  border-top-color:transparent ;
  border-left-color: transparent;
}

.vip-renewal .tr-icon {
  color: #FFFFFF;
  position:absolute;
  font-weight: 600;
}
</style>
