<template>
    <div class="enlarge">
        <div v-if="this.payType == 5">
            <!--            <div style="font-size: 20px;color: #333333;margin:10px 0 10px 10px; font-weight: 600">请选择不同档位的会员标准：</div>-->
            <div style="display: flex; flex-wrap: wrap">
              <div v-for="(item,index) in spaceList" :key="index">
                <div @click="setSpace(index)" class="hand op money-box" :class="index == zIndex?'select-box':''">
                  <div style="text-align: center;">
                    <div style="font-size: 30px; margin-top: 26px; font-weight: 600;">
                      {{item.spaceSize}}GB
                    </div>
                    <div style="font-size: 20px; margin-top: 20px; font-weight: 600">
                      ￥{{item.unitPrice}}
                    </div>
                  </div>
                  <div class="select-icon" v-if="index == zIndex"><i class="el-icon-check tr-icon"></i></div>
                </div>
              </div>
            </div>
        </div>

        <el-divider v-if="this.payType == 5"></el-divider>

        <div  style="font-size: 20px;color: #444444;margin:20px 0 20px 10px">请确认您的订单详情：为百工宝账号
            <span style="color:#EE3F4D">{{user.nickName}}</span>需支付
            <span style="color:#EE3F4D">{{(payAmt)}}元</span>，请完成支付操作
        </div>
        <div style="width: 100%;height: 35px;">
            <div @click="payOrderNow" style="width: 80px;line-height: 35px;background: red;text-align: center;float: right;color: #FFFFFF;border-radius: 12px;cursor: pointer;">立即下单</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "EnlargeSpace",
        components: {},
        data() {
            return {
                spaceList: [],
                zIndex: '',
                payAmt:0,
                spaceSize: 0,
                orderType: '',
            }
        },

        props: {
            payType: {
                type: String,  // 4空间续费 5扩容空间
                required: true, // 设置为 true 表示这个 prop 是必须的
                default() {
                    return ''
                }
            },
        },

        computed: {
            user() {
                return this.$store.state.user;
            },
        },

        watch: {
            payType: {
                handler() {
                    this.payAmt = 0;
                    this.spaceSize = 0;
                    this.getPackageInfo();
                    if (this.payType == 4) {
                        this.orderType = '4';
                    }
                    if (this.payType == 5) {
                        this.getSpaceList();
                        this.orderType = '5';
                    }

                }
            }
        },

        created() {
            var that = this;
            //预支付订单监听
            this.$EventBus.$EventBus.$off("prePayOrder");
            this.$EventBus.$on("prePayOrder", (data) => {
                this.$nextTick(() => {
                    if (data.code == 100) {
                        that.toPaySelect(data.toPay);
                    }
                });
            });
        },

        mounted() {
            this.getPackageInfo();
            if (this.payType == 4) {
                this.orderType = '4';
            }
            if (this.payType == 5) {
                this.getSpaceList();
                this.orderType = '5';
            }

        },

        methods: {
            //立即下单
            payOrderNow(){
                var that = this;
                //组装微信、支付宝首购需要的数据
                let parameter = {};
                parameter.spaceSize = that.spaceSize;

                let params = that.$store.state.payParams;
                params.type = 1;
                params.payAmt = that.payAmt;
                params.orderType = that.orderType;
                params.payName = "";
                params.payParams = parameter;
                that.sign = that.utils.random_string(6);
                that.newApi.addAndSavePrePayOrder({type:1,payParams:JSON.stringify(params),sign:that.sign}).then(() => {
                    that.b('/newAdaPay/?sign='+that.sign);
                })
            },

            // 获取套餐详情
            getPackageInfo() {
                this.newApi.packageInfo({type: 2}).then((res) => {
                    this.payAmt = res.data.totalPrice;
                })
            },

            //获取空间价格列表
            getSpaceList(){
                var that = this;
                that.newApi.packageSpaceList().then((res) => {
                    if(res.isSuccess == 1){
                        that.spaceList = res.data;
                        this.setSpace(0)
                    }
                })
            },

            //选择哪种套餐
            setSpace(index) {
                var that = this;
                that.zIndex = index;
                that.spaceSize = that.spaceList[index].spaceSize;
                that.newApi.getEnlargeSpacePrice({spaceSize: that.spaceSize}).then(res =>{
                    that.payAmt = res.data.price;
                });

            },

            toPaySelect(data){
                var that = this;
                var params = {};
                params.orderNo = data.orderNo;
                params.code = data.code;
                params.payPwd = data.payPwd;

                let parameter = {};
                //判断是对公还是公绑私
                if(data.activeName != 'corporate'){
                    parameter.payType = data.payChannel;
                    parameter.price = that.utils.keepTwoDecimalFull(that.payAmt);
                    parameter.orderNo = data.orderNo;
                    if (this.payType == 4) {
                        parameter.year = 1;
                        that.spaceBuy(parameter, params);
                    }
                    if (this.payType == 5) {
                        parameter.spaceSize = that.spaceSize;
                        that.firstBuy(parameter, params);
                    }
                }else{
                    if (this.payType == 4) {
                        parameter.year = 1;
                        that.newApi.renewalSpaceDetermine(parameter).then(() => {})
                    }
                    if (this.payType == 5) {
                        parameter.spaceSize = that.spaceSize;
                        that.newApi.enlargeSpaceDetermine(parameter).then(() => {})
                    }
                }

            },

            // 空间扩容接口
            firstBuy(parameter, params) {
                var that = this;
                that.newApi.enlargeSpaceDetermine(parameter).then((res) => {
                    if(res.isSuccess == 1){
                        that.newApi.alpplyBankCardPayDetermine(params).then(() => {})
                    }
                })
            },

            // 空间续费
            spaceBuy(parameter, params) {
                var that = this;
                that.newApi.renewalSpaceDetermine(parameter).then((res) => {
                    if(res.isSuccess == 1){
                        that.newApi.alpplyBankCardPayDetermine(params).then(() => {})
                    }
                })
            },
        }
    }
</script>

<style scoped>
.enlarge .money-box {
  width: 200px;
  height: 140px;
  border-radius: 10px;
  border: 1px solid #CED0D4;
  margin: 10px 10px 0 0;
  position: relative
}

.enlarge .select-box {
  background: url("~@/pages/user/imgs/moneySelect.png");
  color: #018478;
}

.enlarge .select-icon {
  color: #FFFFFF;
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  right: 0;
  border: 18px solid #fff;
  border-bottom-right-radius: 10px;
  border-bottom-color: #009675;
  border-right-color: #009675;
  border-top-color:transparent ;
  border-left-color: transparent;
}

.enlarge .tr-icon {
  color: #FFFFFF;
  position:absolute;
  font-weight: 600;
}
</style>
