<template>
    <div class="my-renewal" style="overflow: hidden; padding-bottom: 100px; margin-left: 10px">
        <div style="font-size: 20px;margin:0 0 20px 0; font-weight: 600;color: #BBBBBB">——————————————— 当前账户详情 ——————————————</div>
        <div style="display: flex; justify-content: space-between">
            <div style="font-size: 18px; line-height: 40px">
                <div><span style="font-weight: 600">年费标准：</span><span style="color: #999999">{{vipInfo.price}}元/年</span></div>
                <div><span style="font-weight: 600">购买日期：</span><span style="color: #999999">{{userWalletInfo.vipStartTime}}</span></div>
                <div v-if="userWalletInfo.isExpire == 1"><span style="font-weight: 600">年费会员截止日期：</span><span style="color: #1479FF">{{userWalletInfo.vipEndTime}}</span></div>
                <div v-if="userWalletInfo.isExpire == 0"><span style="font-weight: 600;color: red;">会员过期日期：</span><span style="color: #1479FF">{{userWalletInfo.vipEndTime}}</span></div>
                <div v-if="userWalletInfo.isExpire == -1"><span style="font-weight: 600;color: #a6a6a6;">会员已过期：</span><span style="color: #a6a6a6">{{userWalletInfo.vipEndTime}}</span></div>
            </div>
            <div v-if="userWalletInfo.isExpire <= 0" class="pay-btn hand op" style="color: #EE3F4D; border: 1px #EE3F4D solid; margin-top: 60px" @click="showReWindow('2')">会员续费</div>
        </div>
        <hr style="color: #CCCCCC; margin: 20px 0">
        <div style="margin-bottom: 30px">
            <SpaceBar v-if="JSON.stringify(sizeInfo) !== '{}'" :spaceSize="sizeInfo"></SpaceBar>
        </div>
        <div style="display: flex; justify-content: space-between">
            <div style="font-size: 18px; line-height: 40px">
                <div><span style="font-weight: 600">空间容量：</span><span style="color: #999999"><span style="color: #EE3F4D">{{userWalletInfo.spaceSize}}GB</span></span></div>
                <div><span style="font-weight: 600">购买日期：</span><span style="color: #999999">{{userWalletInfo.spaceStartTime}}</span></div>
                <div v-if="userWalletInfo.isSpace == 1"><span style="font-weight: 600">空间使用截止日期：</span><span style="color: #1479FF">{{userWalletInfo.spaceEndTime}}</span></div>
                <div v-if="userWalletInfo.isSpace == 0"><span style="font-weight: 600;color: red;">空间到期日期：</span><span style="color: #1479FF">{{userWalletInfo.spaceEndTime}}</span></div>
                <div v-if="userWalletInfo.isSpace == -1"><span style="font-weight: 600;color: #a6a6a6;">空间已过期：</span><span style="color: #a6a6a6">{{userWalletInfo.spaceEndTime}}</span></div>
            </div>
            <div style="display: flex">
                <div @click="showReWindow('4')" class="pay-btn hand op" style="color: #EE3F4D; border: 1px #EE3F4D solid; margin-top: 60px;margin-right: 10px;">空间续费</div>
                <div @click="showReWindow('5')" class="pay-btn hand op" style="color: #1479FF; border: 1px #1479FF solid; margin-top: 60px; ">扩容空间</div>
            </div>
        </div>

        <!--续费弹窗-->
        <el-dialog :visible.sync="reShow" width="39%" :show-close="false" class="event-form" :close-on-click-modal="false">
            <div class="top">
                {{btnType}}
                <img src="@/assets/imgs/index/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="reShow = false;payType = 0">
            </div>
            <div style="padding: 20px 40px; display: flex; flex-wrap: wrap; justify-content: space-between">
                <VipRenewal v-if="payType == 2" :payType="payType" @childEvent="handleChildEvent" ref="childVip"></VipRenewal>
                <EnlargeSpace v-if="payType == 4 || payType == 5" :payType="payType" @childEvent="handleChildEvent" ref="childSpace"></EnlargeSpace>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import VipRenewal from "./VipRenewal";
    import EnlargeSpace from "./EnlargeSpace";
    import SpaceBar from "@/components/common/spaceBar.vue";
    export default {
        name: "MyRenewal",
        components: {SpaceBar, EnlargeSpace, VipRenewal},
        data() {
            return {
                reShow: false,
                payType: '',
                btnType: '会员续费',
                vipInfo: {},
                spaceInfo: {},
                userWalletInfo:this.info,
                sizeInfo: {}
            }
        },
        computed: {
            user() {
                return this.$store.state.user;
            },
        },
        props: {
            info: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        mounted() {
            var that = this;
            this.getPackageInfo();
            this.getMySpaceSize()
            // 先销毁事件，防止重复触发
            this.$EventBus.$EventBus.$off("payMsg");
            this.$EventBus.$on("payMsg", (data) => {
                this.$nextTick(() => {
                    if(data.code == 100){
                        console.log("myrenewal");
                        that.reShow = false;
                        setTimeout(function (){
                            that.getPackageInfo();
                            that.getUserWalletInfo();
                        },1000)
                    }
                });
            });
        },

        methods: {
            // 获取空间大小
            getMySpaceSize() {
              this.newApi.getMySpaceSize().then(res => {
                this.sizeInfo = res.data
              })
            },

            //获取钱包的用户信息
            getUserWalletInfo() {
                var that = this;
                that.newApi.getUserWalletInfo().then(res => {
                    if (res.isSuccess == 1) {
                        that.userWalletInfo = res.data;
                    }
                });
            },

            //子组件控制弹窗显示隐藏
            handleChildEvent(){
                var that = this;
                that.reShow = false;
            },
            // 获取套餐详情
            getPackageInfo() {
                this.newApi.packageInfo({type: 1}).then((res) => {
                    this.vipInfo = res.data
                });
                this.newApi.packageInfo({type: 2}).then((res) => {
                    this.spaceInfo = res.data
                })
            },

            // 操作窗口
            showReWindow(payType) {
                this.reShow = true;
                this.payType = payType;
                if (payType === '2') {
                    this.btnType = '会员续费'
                }
                if (payType === '4') {
                    this.btnType = '空间续费'
                }
                if (payType === '5') {
                    this.btnType = '扩容空间'
                }
            },

        }
    }
</script>

<style scoped>
    .my-renewal .pay-btn {
        font-size: 18px;
        font-weight: 600;
        width: 150px;
        height: 24px;
        padding: 12px 0;
        text-align: center;
    }

    .event-form .top {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        color: #444444;
        border: 1px solid #CECFD0;
        height: 60px;
        box-sizing: border-box;
        line-height: 60px;
    }

    /deep/ .event-form .el-dialog__header {
        padding: 0;
    }

    /deep/ .event-form .el-dialog__body {
        padding: 0;
    }

</style>
