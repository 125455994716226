<template>
  <div class="member">
    <div style="width: 100%; height: 80px; background-color: #FFFFFF">
      <div style="display: flex; justify-content: space-between; padding-top: 16px; width: 1500px; margin: 0 auto">
        <img src="@/pages/user/imgs/zu3484.png" style="width: 334px; height: 49px">
        <div>
          <AvatarMenu></AvatarMenu>
        </div>
      </div>
    </div>
    <div class="top">
        <div class="top-body">
          <div style="float: left; color: #FFD49C; margin-top: 80px">
            <div style="font-size: 40px;">百工宝会员（VIP）</div>
            <div style="font-size: 20px; margin: 20px 0 40px 0">搭上数字经济末班车·开通您的服务专线</div>
            <span class="tag-btn" v-for="(item, index) in btnType" :key="index">{{item}}</span>
              <div v-if="user.userType == 1">
                  <div @click="function() {type = 'pay'}" class="top-btn hand op sc" v-if="userWalletInfo.vipEndTime != null" style="color: #F94950">立即续费</div>
                  <div @click="function() {type = 'pay'}" class="top-btn hand op sc" v-else style="color: #1a1a1a">立即开通</div>
              </div>
              <div v-if="user.userType == 2">
                  <div @click="function() {type = 'pay'}" class="top-btn hand op sc" v-if="userWalletInfo.subEndTime != null" style="color: #F94950">立即续费</div>
                  <div @click="function() {type = 'pay'}" class="top-btn hand op sc" v-else style="color: #1a1a1a">立即开通</div>
              </div>
          </div>
          <div style="float: right;">
            <div class="card-box">
              <div class="card-body">
                  <div v-if="user.userType == 1">
                      <div class="card-title" style="color: #351702" v-if="userWalletInfo.vipEndTime == null">会员尚未开通</div>
                      <div class="card-title" style="color: #351702" v-if="userWalletInfo.vipEndTime != null && (userWalletInfo.isExpire == 1 || userWalletInfo.isExpire == 0)">已开通会员</div>
                      <div class="card-title" style="color: red" v-if="userWalletInfo.vipEndTime != null && userWalletInfo.isExpire == -1">会员已过期</div>
                  </div>
                  <div v-if="user.userType == 2">
                      <div class="card-title" style="color: #351702" v-if="userWalletInfo.subEndTime == null">子账号尚未开通</div>
                      <div class="card-title" style="color: #351702" v-else>已开通子账号</div>
                  </div>
                <div style="display: flex; align-items: center; margin: 30px 0 30px 20px; width: 200px">
                  <img :src="user.headIco" style="width: 60px; height: 60px; border-radius: 50%; margin-right: 10px">
                  <div style="font-weight: 600; font-size: 18px;">{{user.nickName}}</div>
                </div>
                <div style="display: flex; align-items: center; margin:0 0 22px 20px">
                  <img src="@/assets/imgs/index/vipIcon.png" style="width: 30px; height: 14px; margin-right: 10px">
                  <div style="color: #8B6935">成为会员，尊享6亿+优质内容免费下载</div>
                </div>
                <div v-if="user.userType == 1" style="color: #502D0B; margin-left: 20px; font-size: 16px; font-weight: 600">会员到期日：<span v-if="userWalletInfo.vipEndTime == null">暂未开通</span><span v-else>{{userWalletInfo.vipEndTime}}</span></div>
                <div v-if="user.userType == 2" style="color: #502D0B; margin-left: 20px; font-size: 16px; font-weight: 600">子账号到期日：<span v-if="userWalletInfo.subEndTime == null">暂未开通</span><span v-else>————{{userWalletInfo.subEndTime}}</span></div>
              </div>
            </div>
          </div>
          <div class="top-nav">
            <div class="nav-tag hand sc" :class="type === 'home' ? 'nav-bottom' : ''" @click="changeNav('home')">首页</div>
            <div class="nav-tag hand sc" :class="type === 'user' ? 'nav-bottom' : ''" @click="changeNav('user')">用户权益</div>
            <div class="nav-tag hand sc" :class="type === 'member' ? 'nav-bottom' : ''" @click="changeNav('member')">会员权益</div>
            <div class="nav-tag hand sc" :class="type === 'pay' ? 'nav-bottom' : ''" @click="changeNav('pay')">支付中心</div>
          </div>
        </div>
    </div>
    <div class="body">
      <MemberHome v-if="type === 'home'"></MemberHome>
      <UserEquity v-if="type === 'user'"></UserEquity>
      <MemberEquity :onChange="function(e) {type = e}" v-if="type === 'member'"></MemberEquity>
      <PayCenter :info="userWalletInfo" :onChange="changeUserWallInfo" v-if="type === 'pay'"></PayCenter>
    </div>
  </div>
</template>

<script>
import MemberHome from "@/pages/user/member/components/MemberHome.vue";
import UserEquity from "@/pages/user/member/components/UserEquity.vue";
import MemberEquity from "@/pages/user/member/components/MemberEquity.vue";
import PayCenter from "@/pages/user/member/components/PayCenter.vue";
import AvatarMenu from "@/components/common/AvatarMenu.vue";

export default {
  name:'member',
  components: {
    AvatarMenu,
    PayCenter,
    MemberEquity,
    UserEquity,
    MemberHome
  },
  data() {
    return {
      btnType: ['会员权益', '专属标识', '专属客服'],
      type: 'home',
      userWalletInfo:{},
    }
  },
  props:{
  },
  computed:{
    user() {
      return this.$store.state.user
    },
  },
  created(){
  },
  mounted() {
      var that = this;
      this.getUserWalletInfo();
      // 先销毁事件，防止重复触发
      this.$EventBus.$EventBus.$off("payMsg");
      //支付回调广播
      this.$EventBus.$on("payMsg", (data) => {
          this.$nextTick(() => {
              if(data.code == 100){
                  console.log("user/index");
                  setTimeout(function (){that.getUserWalletInfo();},500)
              }
          });
      });
  },
  watch: {
  },
  methods:{
        changeNav(e) {
          this.type = e
        },

      changeUserWallInfo(e){
          var that = this;
          console.log('index changeUserWallInfo')
          this.type = e;
          that.getUserWalletInfo();

      },

      //获取钱包的用户信息
      getUserWalletInfo() {
         var that = this;
         that.newApi.getUserWalletInfo().then(res => {
             if (res.isSuccess == 1) {
                 that.userWalletInfo = res.data;
             }
         });
      },

  },


}
</script>

<style scoped>
.member {
  min-height: 100vh;
  background-color: #F2F2F2;
}

.member .top {
  width: 1903px;
  height: 420px;
  margin: 0 auto;
  background-image: url("../imgs/memberBack.png");
  background-repeat: no-repeat;
  background-color: #1a1a1a;
}

.member .body {
  width: 1500px;
  margin: 10px auto;
}

.member .top .top-body {
  width: 1500px;
  height: 420px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}

.member .top .tag-btn {
  width: 120px;
  line-height: 32px;
  display: inline-block;
  text-align: center;
  margin-right: 20px;
  border: 1px #FFD49C solid;
  border-radius: 5px;
}

.member .top .top-btn {
  width: 190px;
  font-size: 18px;
  color: #351702;
  line-height: 42px;
  text-align: center;
  border-radius: 30px;
  margin-top: 50px;
  background: linear-gradient( 270deg, #F9DCB3 0%, #F8C989 100%);
}

.member .top .card-box {
  width: 532px;
  height: 300px;
  margin-top: 40px;
  background-image: url('../../../assets/imgs/index/vipCard.png')
}

.member .top .card-body {
  padding-top: 60px;
}

.member .top .card-title {
  width: 140px;
  height: 30px;
  font-size: 16px;
  border-radius: 10px 0 0 0;
  padding: 8px 0 0 20px;
}

.member .top .top-nav {
  position: absolute;
  left: 0;
  bottom: 0;
  color: #FFD49C;
  overflow: hidden;
}

.member .top .nav-tag {
  float: left;
  font-size: 18px;
  line-height: 42px;
  font-weight: 600;
  margin-right: 50px;
}

.member .top .nav-bottom {
  border-bottom: 3px #FFD49C solid;
}
</style>
