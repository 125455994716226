<template>
    <div class="annual-fee" style="overflow: hidden; padding-bottom: 100px">
        <div>
            <div style="font-size: 18px;color: #333333;margin:0 0 10px 10px; font-weight: 600">请选择不同档位的会员标准：</div>
            <div style="display: flex; flex-wrap: wrap">
                <div v-for="(item,index) in vipList" :key="index">
                    <div @click="SetMeal(index)" class="hand op money-box" :class="index == zIndex?'select-box':''">
                        <div style="text-align: center; font-size: 20px;margin:20px 0 0 0;">购买{{item.month}}月</div>
                        <div style="text-align: center; font-size: 34px;font-weight: bold;margin:20px 0 0 0;">￥{{item.price}}</div>
                        <div class="select-icon" v-if="index == zIndex"><i class="el-icon-check tr-icon"></i></div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div style="font-size: 18px;color: #333333;margin:36px 0 10px 10px; font-weight: 600">请选择不同档位的空间大小：</div>
            <div style="display: flex; flex-wrap: wrap">
                <div v-for="(item,index) in spaceList" :key="index">
                    <div @click="setSpace(index)" class="hand op money-box" :class="index == sIndex?'select-box':''">
                        <div style="text-align: center;">
                            <div style="font-size: 30px; margin-top: 26px; font-weight: 600;">
                                {{item.spaceSize}}GB
                            </div>
                            <div style="font-size: 20px; margin-top: 20px; font-weight: 600">
                                ￥{{item.unitPrice}}
                            </div>
                        </div>
                      <div class="select-icon" v-if="index == sIndex"><i class="el-icon-check tr-icon"></i></div>
                    </div>
                </div>
            </div>
        </div>

        <div style="display: flex; justify-content: end; margin-right: 80px; margin-top: 10px;font-size: 16px;">

            <div style="display: flex;" v-if="(beforePayamt + beforesPay) > (payAmt + sPay)">
                <div style=" margin-top: 8px;color: #C1C1C1;">优惠前：</div>
                <div class="text-line-through-custom" style=" color: #EE3F4D;line-height: 35px;color: #a6a6a6;margin-right: 20px;">￥{{utils.keepTwoDecimalFull(beforePayamt + beforesPay)}}</div>
                <div style="margin-top: 8px;color: #FF9800;">优惠后：</div>
                <div style=" color: #EE3F4D;font-size: 18px;line-height: 40px;">￥{{utils.keepTwoDecimalFull(payAmt + sPay)}}</div>
            </div>
            <div v-else style="display: flex;" >
                <div style="margin-top: 8px;color: #FF9800;">合计：</div>
                <div style=" color: #EE3F4D;font-size: 18px;line-height: 40px;">￥{{utils.keepTwoDecimalFull(payAmt + sPay)}}</div>
            </div>

        </div>

        <el-divider></el-divider>

        <div style="font-size: 20px;color: #444444;margin:55px 0 25px 35px;">
          <div style="font-weight: 600; margin-bottom: 10px">请确认您的订单详情：</div>
          为百工宝账号<span style="color:#2970FF">{{user.nickName}}</span>支付<span style="color:#EE3F4D">购买{{zMonth}}月</span>个人会员、<span style="color:#EE3F4D">{{spaceSize}}G</span>空间容量，共需支付<span style="color:#EE3F4D">{{((payAmt * 100)  + (sPay * 100)) / 100}}元</span>，请完成支付操作
        </div>
        <div style="width: 100%;height: 35px;">
            <div @click="payOrderNow" style="width: 80px;line-height: 35px;background: red;text-align: center;float: right;color: #FFFFFF;border-radius: 12px;cursor: pointer;">立即下单</div>
        </div>

    </div>
</template>

<script>
export default {
    components: {
    },

    computed: {
        user() {
            return this.$store.state.user;
        },
    },
    userWalletInfo: {
        type:Object,
        default() {
            return {}
        }
    },
    data () {
        return {
            orderType: '9',
            vipList:[],
            zIndex:0,
            packageId:0,
            //adapay支付组件参数
            orderNo:'',
            loading:false,
            payAmt:0,
            zMonth: 0,
            spaceList: [],
            sIndex:0,
            spaceSize: 0,
            sPay:0,
            wxOrderType: '',
            showMessage:false,
            sign:'',
            beforePayamt:0,
            beforesPay:0,
        }
    },
    created() {
        var that = this;
        //预支付订单监听
        this.$EventBus.$EventBus.$off("prePayOrder");
        this.$EventBus.$on("prePayOrder", (data) => {
            this.$nextTick(() => {
                if (data.code == 100) {
                    that.bankPay(data.toPay);
                }
            });
        });
    },
    mounted:function(){
        this.getVipList();
        this.getSpaceList();
    },
    methods:{
        //立即下单
        payOrderNow(){
            var that = this;
            if (that.user.realNameStatus != 2){
                that.utils.confirm('请先完成实名认证', function(){
                    that.b('/auth');
                })
                return false;
            }
            let params = that.$store.state.payParams;
            //组装微信、支付宝首购需要的数据
            let parameter = {};
            parameter.vipMonth = that.zMonth;
            parameter.vipPrice = that.utils.keepTwoDecimalFull(that.payAmt);
            parameter.spaceSize = that.spaceSize;
            parameter.spacePrice = that.utils.keepTwoDecimalFull(that.sPay);

            params.type = 1;
            params.payAmt = ((that.payAmt * 100)  + (that.sPay * 100)) / 100;
            params.orderType = that.orderType;
            params.payName = "";
            params.payParams = parameter;
            that.sign = that.utils.random_string(6);
            that.newApi.addAndSavePrePayOrder({type:1,payParams:JSON.stringify(params),sign:that.sign}).then(() => {
                that.b('/newAdaPay/?sign='+that.sign);
            })
        },

        //获取年费列表
        getVipList(){
            var that = this;
            that.newApi.packageVipList().then((res) => {
                if(res.isSuccess == 1){
                    that.vipList = res.data;
                    this.SetMeal(0);
                }
            })
        },

        //获取空间价格列表
        getSpaceList(){
            var that = this;
            that.newApi.packageSpaceList().then((res) => {
                if(res.isSuccess == 1){
                    that.spaceList = res.data;
                    this.setSpace(0)
                }
            })
        },

        //选择VIP套餐
        SetMeal(index){
            var that = this;
            that.zIndex = index;
            that.zMonth = that.vipList[index].month;
            var params = {
                params : JSON.stringify(this.vipList[index])
            }
            that.newApi.validationVipPrice(params).then((res) => {
                if(res.isSuccess == 1){
                    that.payAmt = res.data.payAmt;
                    that.beforePayamt = res.data.beForePayAmt;
                }
            })
        },

        //选择空间容量
        setSpace(index) {
            var that = this;
            that.sIndex = index;
            that.spaceSize = that.spaceList[index].spaceSize;
            var params = {
                params : JSON.stringify(that.spaceList[index])
            }
            that.newApi.validationSpacePrice(params).then((res) => {
                if(res.isSuccess == 1){
                    that.sPay = res.data.payAmt;
                    that.beforesPay = res.data.beForePayAmt;
                }
            })
        },


        bankPay(data){
            var that = this;
            var params = {};
            params.orderNo = data.orderNo;
            params.code = data.code;
            params.payPwd = data.payPwd;

            if(parseInt(that.orderType) == 9){
                let parameter = {};
                parameter.vipMonth = that.zMonth;
                parameter.vipPrice = that.utils.keepTwoDecimalFull(that.payAmt);
                parameter.spaceSize = that.spaceSize;
                parameter.spacePrice = that.utils.keepTwoDecimalFull(that.sPay);
                parameter.orderNo = data.orderNo;
                parameter.buy = data.payChannel;
                that.firstBuy(parameter, params);
            }
        },

        // 首购接口
        firstBuy(parameter, params) {
            var that = this;
            that.newApi.vipAndSpaceAndSubPay(parameter).then((res) => {
                if(res.isSuccess == 1){
                    that.newApi.alpplyBankCardPayDetermine(params).then(() => {})
                }
            })
        },
    }
}
</script>

<style scoped>
    .annual-fee {
        width: 100%;
    }

    .annual-fee .money-box {
      width: 200px;
      height: 140px;
      border-radius: 10px;
      border: 1px solid #CED0D4;
      margin: 10px 10px 0 0;
      position: relative
    }

    .annual-fee .select-box {
      background: url("~@/pages/user/imgs/moneySelect.png");
      color: #018478;
    }

    .annual-fee .select-icon {
      color: #FFFFFF;
      position: absolute;
      width: 0;
      height: 0;
      bottom: 0;
      right: 0;
      border: 18px solid #fff;
      border-bottom-right-radius: 10px;
      border-bottom-color: #009675;
      border-right-color: #009675;
      border-top-color:transparent ;
      border-left-color: transparent;
    }

    .annual-fee .tr-icon {
      color: #FFFFFF;
      position:absolute;
      font-weight: 600;
    }

    .cover_border{
        width: 148px;height: 196px;background: #FFFFFF;border-radius: 8px 8px 8px 8px;border: 1px solid #CED0D4;float: left;margin:10px 0 0 20px
    }
    .cover_border.active{
        height:196px;color:#FFFFFF;background: #018679;
    }

    .text-line-through-custom {
        text-decoration: line-through;
        text-decoration-color: red;
        text-decoration-thickness: 2px;
        text-decoration-style: double;
        text-underline-position: under;
    }

</style>
