<template>
  <div class="member-home">
    <div class="body-box">
      <div class="box-title">
        <div class="line" style="background-color: #F2BE45"></div>
        <img class="images" src="@/pages/user/imgs/member/starLeft.png">
        <div class="title-font" style="color: #F2BE45">会员特权</div>
        <img class="images" src="@/pages/user/imgs/member/starRight.png">
        <div class="line" style="background-color: #F2BE45"></div>
      </div>
      <div style="display: flex; flex-wrap: wrap">
          <div v-for="(item, index) in iconList" :key="index" class="icon-box" :style="{marginRight: ((index + 1) % 6 === 0 ? '0px' : '10px')}">
            <img :src="item.icon" style="width: 60px; height: 60px; margin-bottom: 20px">
            <div style="font-size: 18px; margin-bottom: 10px">{{item.name}}</div>
            <div style="font-size: 14px; color: #999999">{{item.content}}</div>
          </div>
      </div>
    </div>
    <div class="body-box">
      <div class="box-title">
        <div class="line" style="background-color: #1a1a1a"></div>
        <img class="images" src="@/pages/user/imgs/member/blackLeft.png">
        <div class="title-font">特权对比</div>
        <img class="images" src="@/pages/user/imgs/member/blackRight.png">
        <div class="line" style="background-color: #1a1a1a"></div>
      </div>
      <div class="table-box">
        <div class="module-privilege">
          <ul class="privilege-ul name-ul">
            <li class="privilege-item first-item">
              <p class="title">
                <span>功能特权</span>
              </p>
            </li>
            <li class="privilege-item" style="background: #fbf9f8">
              专属客服
            </li>
            <li class="privilege-item">
              昵称红名
            </li>
            <li class="privilege-item" style="background: #fbf9f8">
              修改昵称
            </li>
            <li class="privilege-item">
              动态置顶
            </li>
            <li class="privilege-item" style="background: #fbf9f8">
              评论置顶
            </li>
            <li class="privilege-item">
              关注上限
            </li>
            <li class="privilege-item">
              ROOM上限
            </li>
            <li class="privilege-item">
              赞赏功能
            </li>
            <li class="privilege-item" style="background: #fbf9f8">
              内容自荐
            </li>
            <li class="privilege-item">
              视频收益
            </li>
            <li class="privilege-item" style="background: #fbf9f8">
              资料收益
            </li>
            <li class="privilege-item">
              私信留言
            </li>
            <li class="privilege-item" style="background: #fbf9f8">
              首页优先推荐
            </li>
            <li class="privilege-item">
              内容分析
            </li>
            <li class="privilege-item" style="background: #fbf9f8">
              健康保镖
            </li>
            <li class="privilege-item">
              储存空间
            </li>
            <li class="privilege-item" style="background: #fbf9f8">
              下载资料
            </li>
          </ul>
          <ul class="privilege-ul svip-ul active recommend">
            <li class="privilege-item first-item">
              <div class="title">
                <span>百工宝会员</span>
                <img src="@/pages/user/imgs/i2952.png" style="width: 32px; height: 27px; margin-left: 10px; vertical-align: middle; margin-bottom: 12px">
              </div>
            </li>
            <li class="privilege-item" style="background: #fbf4e4"><i class="el-icon-check" style="font-weight: 600; font-size: 26px; color: #6DD56E"></i></li>
            <li class="privilege-item"><i class="el-icon-check" style="font-weight: 600; font-size: 26px; color: #6DD56E"></i></li>
            <li class="privilege-item" style="background: #fbf4e4">3次/年</li>
            <li class="privilege-item">年费会员(VIP)可置顶1条内容</li>
            <li class="privilege-item" style="background: #fbf4e4"><i class="el-icon-check" style="font-weight: 600; font-size: 26px; color: #6DD56E"></i></li>
            <li class="privilege-item">可关注1000人</li>
            <li class="privilege-item">项目10个、兴趣5个、经营2个/年</li>
            <li class="privilege-item"><i class="el-icon-check" style="font-weight: 600; font-size: 26px; color: #6DD56E"></i></li>
            <li class="privilege-item" style="background: #fbf4e4">每月可自荐2次</li>
            <li class="privilege-item"><i class="el-icon-check" style="font-weight: 600; font-size: 26px; color: #6DD56E"></i></li>
            <li class="privilege-item" style="background: #fbf4e4"><i class="el-icon-check" style="font-weight: 600; font-size: 26px; color: #6DD56E"></i></li>
            <li class="privilege-item">60条/天</li>
            <li class="privilege-item" style="background: #fbf4e4">首页推荐机会3次/年</li>
            <li class="privilege-item"><i class="el-icon-check" style="font-weight: 600; font-size: 26px; color: #6DD56E"></i></li>
            <li class="privilege-item" style="background: #fbf4e4"><i class="el-icon-check" style="font-weight: 600; font-size: 26px; color: #6DD56E"></i></li>
            <li class="privilege-item">免费获取2G储存空间</li>
            <li class="privilege-item last-item">在【知识仓库】免费下载10次</li>
          </ul>
          <ul class="privilege-ul no-vip-ul">
            <li class="privilege-item first-item">
              <p class="title">
                <span>普通用户</span>
              </p>
            </li>
            <li class="privilege-item" style="background: #fbf9f8"><i class="el-icon-close" style="font-weight: 600; font-size: 26px; color: #F94950"></i></li>
            <li class="privilege-item"><i class="el-icon-close" style="font-weight: 600; font-size: 26px; color: #F94950"></i></li>
            <li class="privilege-item" style="background: #fbf9f8">1次/年</li>
            <li class="privilege-item"><i class="el-icon-close" style="font-weight: 600; font-size: 26px; color: #F94950"></i></li>
            <li class="privilege-item" style="background: #fbf9f8"><i class="el-icon-close" style="font-weight: 600; font-size: 26px; color: #F94950"></i></li>
            <li class="privilege-item">可关注50人</li>
            <li class="privilege-item">项目5个、兴趣1个、经营1个/年</li>
            <li class="privilege-item"><i class="el-icon-close" style="font-weight: 600; font-size: 26px; color: #F94950"></i></li>
            <li class="privilege-item" style="background: #fbf9f8"><i class="el-icon-close" style="font-weight: 600; font-size: 26px; color: #F94950"></i></li>
            <li class="privilege-item"><i class="el-icon-close" style="font-weight: 600; font-size: 26px; color: #F94950"></i></li>
            <li class="privilege-item" style="background: #fbf9f8"><i class="el-icon-close" style="font-weight: 600; font-size: 26px; color: #F94950"></i></li>
            <li class="privilege-item">3条/天</li>
            <li class="privilege-item" style="background: #fbf9f8"><i class="el-icon-close" style="font-weight: 600; font-size: 26px; color: #F94950"></i></li>
            <li class="privilege-item"><i class="el-icon-close" style="font-weight: 600; font-size: 26px; color: #F94950"></i></li>
            <li class="privilege-item" style="background: #fbf9f8"><i class="el-icon-close" style="font-weight: 600; font-size: 26px; color: #F94950"></i></li>
            <li class="privilege-item"><i class="el-icon-close" style="font-weight: 600; font-size: 26px; color: #F94950"></i></li>
            <li class="privilege-item" style="background: #fbf9f8"><i class="el-icon-close" style="font-weight: 600; font-size: 26px; color: #F94950"></i></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'MemberHome',
  components: {
  },
  data() {
    return {
      iconList: [
        {name: '专属标识', content: '百工宝会员专属身份标识', icon: require('@/pages/user/imgs/i2897.png')},
        {name: '专属客服', content: '百工宝会员专属客服团队', icon: require('@/pages/user/imgs/i2898.png')},
        {name: '昵称红名', content: '用户会员昵称会变为红色', icon: require('@/pages/user/imgs/i2899.png')},
        {name: '修改昵称', content: '用户昵称可提升修改上限', icon: require('@/pages/user/imgs/i2900.png')},
        {name: '动态置顶', content: '朋友圈动态可以进行置顶', icon: require('@/pages/user/imgs/i2901.png')},
        {name: '评论置顶', content: '评论可以置顶为热门评论', icon: require('@/pages/user/imgs/i2902.png')},
        {name: '关注上限', content: '会员用户可提升关注上限', icon: require('@/pages/user/imgs/i2903.png')},
        {name: 'ROOM上限', content: '增加ROOM创建上限', icon: require('@/pages/user/imgs/i2904.png')},
        {name: '赞赏功能', content: '可以将优质内容进行变现', icon: require('@/pages/user/imgs/i2905.png')},
        {name: '内容自荐', content: '会员可使用自荐信息功能', icon: require('@/pages/user/imgs/i2906.png')},
        {name: '视频收益', content: '可发布原创视频获取收益', icon: require('@/pages/user/imgs/i2907.png')},
        {name: '资料收益', content: '发布独家资料可有偿收费', icon: require('@/pages/user/imgs/i2908.png')},
        {name: '私信留言', content: '增加“未互关”私信上限', icon: require('@/pages/user/imgs/i2909.png')},
        {name: '首页优先推荐', content: '会员可被系统推荐至首页', icon: require('@/pages/user/imgs/i2910.png')},
        {name: '内容分析', content: '对会员发布内容进行分析', icon: require('@/pages/user/imgs/i2911.png')},
        {name: '健康保镖', content: '平台会自动拦截有害信息', icon: require('@/pages/user/imgs/i2912.png')},
        {name: '免费2G储存空间', content: '开通百工宝会员后即获得', icon: require('@/pages/user/imgs/i2913.png')},
        {name: '免费下载资料', content: '百工宝知识仓库资料限免', icon: require('@/pages/user/imgs/i2914.png')},
      ]
    }
  },
  props:{
  },
  computed:{
    user() {
      return this.$store.state.user
    },
  },
  created(){
  },
  mounted() {
  },
  watch: {
  },
  methods:{

  },


}
</script>

<style scoped>
.member-home {
  width: 100%;
}

.member-home .body-box {
  padding: 20px 30px;
  background-color: #FFFFFF;
  margin-bottom: 10px;
}

.member-home .box-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.member-home .line {
  width: 100px;
  height: 2px;
}

.member-home .images {
  width: 56px;
  height: 56px;
  margin: 0 2px;
}

.member-home .title-font {
  font-size: 24px;
  font-weight: 600;
}

.member-home .icon-box {
  width: 176px;
  height: 140px;
  margin: 10px;
  padding: 24px 20px;
  text-align: center;
  box-shadow: 0 2px 6px 1px rgba(0,0,0,0.16);
  border-radius: 10px 10px 10px 10px;
}

.module-privilege {
  width: 1420px;
  height: 1440px;
  margin: 10px auto 40px;
  position: relative;
}

.module-privilege ul {
  list-style: none;
}

.module-privilege {
  width: 1420px;
  height: 1440px;
  margin: 10px auto 40px;
  position: relative;
}

.module-privilege .privilege-ul.name-ul {
  left: 0;
}

.module-privilege .privilege-ul {
  float: left;
  border: 1px solid #d3d1d0;
  background: #fff;
  border-radius: 5px;
  width: 468px;
  position: absolute;
}

.module-privilege .privilege-ul .title {
  font-size: 22px;
  font-weight: 600;
}

.module-privilege .privilege-ul.active {
  border-color: #F2DDAF;
  background-color: #FFFAEB;
  margin-top: -8px;
  border-radius: 5px;
  width: 470px;
  z-index: 10;
}

.module-privilege .privilege-ul.svip-ul {
  left: 468px;
  color: #CA963B;
  -moz-box-shadow: 0 0 10px 2px rgba(0, 0, 0, .08);
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, .08);
}

.module-privilege .privilege-ul .privilege-item.first-item {
  height: 82px;
  line-height: 82px;
}

.module-privilege .privilege-ul .privilege-item {
  text-align: center;
  height: 66px;
  line-height: 66px;
  font-size: 18px;
}

.module-privilege .privilege-ul.active .privilege-item.first-item {
  height: 90px;
  line-height: 90px;
}

.module-privilege .privilege-ul.no-vip-ul {
  left: 936px;
}

.module-privilege .privilege-ul.active .privilege-item.last-item {
  height: 72px;
}
</style>
