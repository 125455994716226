<template>
    <div class="organ-first">
        <div style="font-size: 20px;color: #333333;margin:0 0 10px 0; font-weight: 600">请选择子账户数量：<span style="color: #999999; font-size: 16px"></span></div>
        <div style="display: flex; flex-wrap: wrap">
            <div v-for="(item,index) in subList" :key="index">
                <div @click="SetMeal(index)" class="hand op money-box" :class="index == zIndex?'select-box':''">
                    <div style="text-align: center; font-size: 20px;margin:20px 0 0 0;">数量{{item.number}}</div>
                    <div style="text-align: center; font-size: 20px;font-weight: bold;margin:20px 0 0 0;">￥{{item.price}}/年</div>
                    <div class="select-icon" v-if="index == zIndex"><i class="el-icon-check tr-icon"></i></div>
                </div>
            </div>
        </div>

        <div style="font-size: 20px;color: #333333;margin:36px 0 10px 10px; font-weight: 600">请选择不同档位的空间容量：<span style="color: #999999; font-size: 16px">（空间容量不随子账号会员标准计算，有效期均为1年）</span></div>
        <div style="display: flex; flex-wrap: wrap">
            <div v-for="(item,index) in spaceList" :key="index">
                <div @click="setSpace(index)" class="hand op money-box" :class="index == sIndex?'select-box':''">
                    <div style="text-align: center;">
                        <div style="font-size: 30px; margin-top: 26px; font-weight: 600">
                            {{item.spaceSize}}GB
                        </div>
                        <div style="font-size: 20px; margin-top: 20px; font-weight: 600">
                            ￥{{item.price}}/年
                        </div>
                      <div class="select-icon" v-if="index == sIndex"><i class="el-icon-check tr-icon"></i></div>
                    </div>
                </div>
            </div>
        </div>

        <div style="display: flex; justify-content: end; margin-right: 80px; margin-top: 10px;font-size: 16px;">

            <div style="display: flex;" v-if="(beforePayamt + beforesPay) > (payAmt + sPay)">
                <div style=" margin-top: 8px;color: #C1C1C1;">优惠前：</div>
                <div class="text-line-through-custom" style=" color: #EE3F4D;line-height: 35px;color: #a6a6a6;margin-right: 20px;">￥{{utils.keepTwoDecimalFull(beforePayamt + beforesPay)}}</div>
                <div style="margin-top: 8px;color: #FF9800;">优惠后：</div>
                <div style=" color: #EE3F4D;font-size: 18px;line-height: 40px;">￥{{utils.keepTwoDecimalFull(payAmt + sPay)}}</div>
            </div>
            <div v-else style="display: flex;" >
                <div style="margin-top: 8px;color: #FF9800;">合计：</div>
                <div style=" color: #EE3F4D;font-size: 18px;line-height: 40px;">￥{{utils.keepTwoDecimalFull(payAmt + sPay)}}</div>
            </div>

        </div>

        <el-divider></el-divider>

        <div  style="font-size: 20px;color: #444444;margin:30px 0 25px 35px">请确认您的订单详情：为百工宝账号
            <span style="color:#EE3F4D">{{user.nickName}}</span>需支付
            <span style="color:#EE3F4D">{{utils.keepTwoDecimalFull(payAmt + sPay)}}元</span>，请完成支付操作
        </div>
        <div style="width: 100%;height: 35px;">
            <div @click="payOrderNow" style="width: 80px;line-height: 35px;background: red;text-align: center;float: right;color: #FFFFFF;border-radius: 12px;cursor: pointer;">立即下单</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OrganFirst",
        components: {},
        computed: {
            user() {
                return this.$store.state.user;
            },
        },

        watch: {
            num: {
                deep: true,
                handler() {
                    this.SetMeal(0)
                },
            },
        },
        data() {
            return {
                subList: [],
                zIndex: '',
                zYear: 0,
                zNumber:0,
                payAmt: 0,
                orderType: '10',
                spaceList: [],
                sIndex: 0,
                spaceSize: 0,
                sPay: 0,
                sign:'',
                beforePayamt:0,
                beforesPay:0,
            }
        },

        created() {
            var that = this;
            //预支付订单监听
            this.$EventBus.$EventBus.$off("prePayOrder");
            this.$EventBus.$on("prePayOrder", (data) => {
                this.$nextTick(() => {
                    if (data.code == 100) {
                        that.bankPay(data.toPay);
                    }
                });
            });
        },

        mounted() {
            this.getSubList();
            this.getSpaceList();
            this.getAllInfo();
        },

        methods: {
            //立即下单
            payOrderNow(){
                var that = this;
                if (that.user.realNameStatus != 2){
                    that.utils.confirm('请先完成实名认证', function(){
                        that.b('/auth');
                    })
                    return false;
                }
                let params = that.$store.state.payParams;
                //组装微信、支付宝首购需要的数据
                let parameter = {};
                parameter.spaceSize = that.spaceSize;
                parameter.spacePrice = that.utils.keepTwoDecimalFull(that.sPay);
                parameter.subYear = that.zYear;
                parameter.subCount = that.zNumber;
                parameter.subPrice = that.utils.keepTwoDecimalFull(that.payAmt);

                params.type = 1;
                params.payAmt = that.utils.keepTwoDecimalFull(that.payAmt + that.sPay);
                params.orderType = that.orderType;
                params.payName = "";
                params.payParams = parameter;
                that.sign = that.utils.random_string(6);
                that.newApi.addAndSavePrePayOrder({type:1,payParams:JSON.stringify(params),sign:that.sign}).then(() => {
                    that.b('/newAdaPay/?sign='+that.sign);
                })
            },

            // 支付后处理
            closeSubPay() {
                this.showPay = false
            },

            //选择VIP套餐
            SetMeal(index){
                var that = this;
                that.zIndex = index;
                that.zYear = this.subList[index].year;
                that.zNumber = this.subList[index].number;
                var params = {
                    params : JSON.stringify(this.subList[index])
                }
                that.newApi.validationVipPrice(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.payAmt = res.data.payAmt;
                        that.beforePayamt = res.data.beForePayAmt;
                    }
                })
            },

            //选择空间容量
            setSpace(index) {
                var that = this;
                that.spaceSize = that.spaceList[index].spaceSize;
                var params = {
                    params : JSON.stringify(that.spaceList[index])
                }
                that.newApi.validationSpacePrice(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.sPay = res.data.payAmt;
                        that.beforesPay = res.data.beForePayAmt;
                    }
                })
            },

            // 获取子账号价格列表
            getSubList() {
                this.newApi.packageSubList().then(res => {
                    this.subList = res.data;
                    this.SetMeal(0)
                })
            },

            //获取空间价格列表
            getSpaceList(){
                var that = this;
                that.newApi.packageSpaceList().then((res) => {
                    if(res.isSuccess == 1){
                        that.spaceList = res.data;
                        that.setSpace(0)
                    }
                })
            },

            // 获取套餐详情
            getAllInfo() {
                this.newApi.packageInfo({type: 3}).then(res => {})
            },

            bankPay(data){
                var that = this;
                let parameter = {};
                parameter.payType = 1;
                parameter.subYear = that.zYear;
                parameter.subCount = that.zNumber;
                parameter.subPrice = that.utils.keepTwoDecimalFull(that.payAmt);
                parameter.spaceSize = that.spaceSize;
                parameter.spacePrice = that.utils.keepTwoDecimalFull(that.sPay);
                parameter.orderNo = data.orderNo;
                parameter.buy = 1;
                //判断是对公还是公绑私
                if(data.activeName != 'corporate'){
                    var params = {};
                    params.orderNo = data.orderNo;
                    params.code = data.code;
                    params.payPwd = data.payPwd;
                    that.firstBuy(parameter, params);
                }else{
                    that.newApi.vipAndSpaceAndSubPay(parameter).then(() => {})
                }

            },
            // 首购接口
            firstBuy(parameter, params) {
                var that = this;
                that.newApi.vipAndSpaceAndSubPay(parameter).then((res) => {
                    if(res.isSuccess == 1){
                        that.newApi.alpplyBankCardPayDetermine(params).then(() => {})
                    }
                })
            },
        },
    }
</script>

<style scoped>
    .organ-first {
      width: 100%;
    }

    .organ-first .money-box {
      width: 200px;
      height: 140px;
      border-radius: 10px;
      border: 1px solid #CED0D4;
      margin: 10px 10px 0 0;
      position: relative
    }

    .organ-first .select-box {
      background: url("~@/pages/user/imgs/moneyOrgan.png");
      color: #0052C1;
    }

    .organ-first .select-icon {
      color: #FFFFFF;
      position: absolute;
      width: 0;
      height: 0;
      bottom: 0;
      right: 0;
      border: 18px solid #fff;
      border-bottom-right-radius: 10px;
      border-bottom-color: #0052C1;
      border-right-color: #0052C1;
      border-top-color:transparent ;
      border-left-color: transparent;
    }

    .organ-first .tr-icon {
      color: #FFFFFF;
      position:absolute;
      font-weight: 600;
    }

    .cover_border{
      width: 148px;height: 196px;background: #FFFFFF;border-radius: 8px 8px 8px 8px;border: 1px solid #CED0D4;float: left;margin:10px 0 0 20px
    }
    .cover_border.active{
      height:196px;color:#FFFFFF;background: #0052C1;
    }

    .text-line-through-custom {
        text-decoration: line-through;
        text-decoration-color: red;
        text-decoration-thickness: 2px;
        text-decoration-style: double;
        text-underline-position: under;
    }
</style>
