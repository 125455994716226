<template>
  <div class="member-equity">
    <div class="body-box">
      <div class="body-left">
        <div v-for="(item, index) in navList" :key="index">
          <div class="title">
            <span style="width: 6px; height: 20px; margin-right: 12px; background-color: #1a1a1a; display: inline-block; vertical-align: middle"></span>
            <span style="vertical-align: middle">{{item.title}}</span>
          </div>
          <div @click="selectOne(child)" class="hand op sc nav-name" v-for="(child, key) in item.list" :style="{color: (selectData.id === child.id ? '#FFB731' : '')}">{{child.name}}</div>
        </div>
      </div>
      <div class="body-right">
        <div style="width: 1140px; height: 180px; background: linear-gradient( 90deg, #F8EDCE 100%, #ECD0A8 0%);border-radius: 10px">
          <div style="height: 180px;display: flex; justify-content: space-between; align-items: center; padding: 0 60px">
            <div style="color: #6B2E0E; display: flex; align-items: center">
              <img src="@/pages/user/imgs/i2915.png" style="width: 100px; height: 100px; border-radius: 50%">
              <div style="font-size: 38px; margin: 0 20px 0 30px">{{selectData.name}}</div>
              <div style="font-size: 20px; margin-top: 16px">{{selectData.content}}</div>
            </div>
            <div v-if="user.isVip === 0" @click="onChange('pay')" class="top-btn hand op sc" style="color: #1a1a1a">立即开通</div>
            <div v-else @click="onChange('pay')" class="top-btn hand op sc" style="color: #F94950">立即续费</div>
          </div>
        </div>
        <div style="margin-top: 40px; margin-left: 20px">
          <div style="margin-bottom: 60px">
            <div style="margin-bottom: 30px; font-size: 22px">特权介绍</div>
            <div style="margin-bottom: 30px; font-size: 18px" v-for="(item, index) in selectData.introduce" :key="index">{{item}}</div>
          </div>
          <div style="margin-bottom: 60px">
            <div style="margin-bottom: 30px; font-size: 22px">特权规则</div>
            <div style="margin-bottom: 30px; font-size: 18px" v-for="(item, index) in selectData.rule" :key="index">{{item}}</div>
          </div>
          <div style="margin-bottom: 60px" v-if="selectData.limit.length > 0">
            <div style="margin-bottom: 30px; font-size: 22px">会员权限</div>
            <el-table
                :data="selectData.limit"
                border
                style="width: 100%;">
              <el-table-column prop="name" label="功能特权" align="center" width="240px"></el-table-column>
              <el-table-column prop="member" label="会员权益" align="center" width="440px">
                <template slot-scope="scope">
                  <div style="color: #FFB731">{{ scope.row.member }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="user" label="普通用户" align="center" width="440px"></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'MemberEquity',
  components: {
  },
  data() {
    return {
      selectData: {},
      navList: [
        {
          title: '身份特权',
          list: [
            {
              id: 1,
              name: '专属标识',
              content: '百工宝会员专属身份标识',
              introduce: ['1、面向用户：所有百工宝会员', '2、服务介绍：百工宝会员专属身份标识，让你时时刻刻展现尊贵的会员身份。百工宝会员，让你的百工宝之旅从此与众不同。'],
              rule: ['1、开通会员后，在您的首页、个人主页昵称后面会出现会员标识。', '2、如果您当前是专家认证或微博大V身份时，您将同时拥有两个标识展现。','3、会员到期后，您首页的会员标识将变灰（此时粉丝们看不到你尊贵的会员标识了），会员标识不再出现。'],
              limit: []
            },
            {
              id: 2,
              name: '专属客服',
              content: '百工宝会员专属客服团队',
              introduce: ['1、面向用户：所有百工宝会员', '2、服务介绍：百工宝会员拥有专属客服团队为您提供服务和帮助。专属客服团队，为您的百工宝之旅保驾护航。'],
              rule: ['1、百工宝年费会员(VIP)拨打4000-接入语音人工服务后享受专属服务，年费会员过期后，专属客服服务失效。', '2、帮助中心提问客服页面，会员用户的提问将能被客服更优先受理。'],
              limit: []
            },
            {
              id: 3,
              name: '昵称红名',
              content: '用户会员昵称会变为红色',
              introduce: ['1、面向用户：所有百工宝会员','2、服务介绍：作为会员，用户昵称会变为耀眼的红色！再也不用担心好友们错过你的每一个动态和分享'],
              rule: ['1、开通会员后，在个人主页的关注列表和粉丝列表里，百工宝会员昵称为红色。','2、会员过期后，百工宝红名功能失效。'],
              limit: []
            }
          ]
        },
        {
          title: '功能特权',
          list: [
            {
              id: 4,
              name: '修改昵称',
              content: '用户昵称可提升修改上限',
              introduce: ['1、面向用户：所有百工宝会员','2、服务介绍：会员改昵称上限提升，想改就改，不再烦恼！'],
              rule: ['1、开通会员后，你的改昵称次数上限立即提升；会员过期后，你的改昵称次数上限会回到普通用户状态。','2、认证用户修改次数详见认证用户规则，请谨慎修改，有问题联系百工宝客服。'],
              limit: [
                {
                  name: '修改昵称',
                  member: '3次/年',
                  user: '1次/年'
                }
              ]
            },
            {
              id: 5,
              name: '动态置顶',
              content: '朋友圈动态可以进行置顶',
              introduce: ['1、面向用户：所有百工宝会员','2、服务介绍：难得发条动态，强忍三天不更新？怕朋友看不见？ 别憋着了，使用动态置顶功能！想说就说，不怕粉丝错过你的\n' +
              '好消息！'],
              rule: ['1、年费会员(VIP)可置顶1条朋友圈动态。','2、在您的个人主页，当点击个人主页内容区域时会出现置顶操作，点击后当前动态将在您的个人主页置顶显示。', '3、会员用户可任意使用置顶功能，置顶动态不受时间限制在个人主页显示。','4、会员到期后，置顶动态则按时间排序恢复在个人主页展示。'],
              limit: []
            },
            {
              id: 6,
              name: '评论置顶',
              content: '评论可以置顶为热门评论',
              introduce: ['1、面向用户：所有百工宝会员','2、服务介绍：选出为您所评中您最中意的评论进行置顶，推荐为热门评论，让更多人看到TA。'],
              rule: ['1、开通会员后，可开通此项功能。','2、会员过期后，此特权失效。'],
              limit: []
            },
            {
              id: 7,
              name: '关注上限',
              content: '会员用户可提升关注上限',
              introduce: ['1、面向用户：所有百工宝会员','2、服务介绍：还在为只能关注500人烦恼么？“关注上限提高”特权让您想加就加，不再烦恼。'],
              rule: ['1、开通会员后，您的关注上限将在24小时之内提高。','2、成为会员可关注1000人。'],
              limit: []
            },{
              id: 8,
              name: 'ROOM上限',
              content: '增加ROOM创建上限',
              introduce: ['1、面向用户：所有百工宝会员','2、服务介绍：ROOM达到上限怎么办？会员专享ROOM上限提高特权，让你畅快热聊！'],
              rule: ['1、开通会员，可创建项目ROOM10个、创建兴趣ROOM5个、创建经营ROOM2个/年。','2、会员过期后，此特权失效。'],
              limit: [{
                name: '创建ROOM',
                member: '项目10个、兴趣5个、经营2个/年',
                user: '项目5个、兴趣1个、经营1个/年'
              }]
            },{
              id: 9,
              name: '赞赏功能',
              content: '可以将优质内容进行变现',
              introduce: ['1、面向用户：所有百工宝会员','2、服务介绍：成为百工宝大V后，可开通【赞赏】功能，将优质内容进行变现。'],
              rule: ['1、成为大V，可开通【赞赏】功能。','2、如账号冻结或被投诉次数超过3次后，此特权失效。'],
              limit: []
            },{
              id: 20,
              name: '视频收益',
              content: '可发布原创视频获取收益',
              introduce: ['1、面向用户：所有百工宝会员','2、服务介绍：发布原创视频获取收益，成为一名原创视频博主吧。'],
              rule: ['1、开通会员后，发布5条以上的原创视频后，才可开通此项功能。','2、会员过期后，此特权失效。'],
              limit: []
            },{
              id: 21,
              name: '资料收益',
              content: '发布独家资料可有偿收费',
              introduce: ['1、面向用户：所有百工宝会员','2、服务介绍：发布原创文章获取收益。'],
              rule: ['1、开通会员后，在【知识仓库】发布独家资料后，可进行有偿收费，获取收益。','1、开通会员后，在【知识仓库】发布独家资料后，可进行有偿收费，获取收益。'],
              limit: []
            },{
              id: 22,
              name: '私信留言',
              content: '增加“未互关”私信上限',
              introduce: ['1、面向用户：所有百工宝会员','2、服务介绍：想和“未互关”的TA聊天，留言帮你更近一步，交友、追星零距离!'],
              rule: ['1、开通会员后，你将自动获得留言条数。留言条数每日发放，当日未消耗完的留言不累计至次日。','2、会员过期后，你的留言条数会自动回到非会员状态。'],
              limit: [ {
                name: '私信留言',
                member: '60条/天',
                user: '3条/天'
              }]
            },{
              id: 10,
              name: '内容自荐',
              content: '会员可使用自荐信息功能',
              introduce: ['1、面向用户：所有百工宝会员','2、服务介绍：百工宝会员可自荐自己所发文章、动态、资料等信息，让好内容被TA看见。'],
              rule: ['1、开通会员，每月可自荐2次。','2、会员过期后，此特权失效。'],
              limit: []
            },{
              id: 11,
              name: '首页优先推荐',
              content: '会员可被系统推荐至首页',
              introduce: ['1、面向用户：所有百工宝会员','2、服务介绍：百工宝会员在开通SHOP在线服务人才或认证专家后，可被推荐至首页，让更多人认识您找到您。'],
              rule: ['1、1.开通会员，并创建店铺并完善店铺资料及产品/服务，可获得首页推荐机会3次/年。','2、开通会员，并认证成为专家，开通专家连线服务后，可获得首页推荐机会3次/年。', '3、会员过期后，此特权失效。'],
              limit: []
            }
          ]
        },
        {
          title: '福利特权',
          list: [
            {
              id: 12,
              name: '内容分析',
              content: '对会员发布内容进行分析',
              introduce: ['1、面向用户：所有百工宝会员','2、服务介绍：可对已发内容进行分析，展示详细数据，以便了解自己所发内容的数据情况。'],
              rule: ['1、开通会员后，可在MENU-我的会员权益-内容分析查看。','2、会员过期后，功能失效。'],
              limit: []
            },
            {
              id: 13,
              name: '健康保镖',
              content: '平台会自动拦截有害信息',
              introduce: ['1、面向用户：所有百工宝会员','2、服务介绍：「健康保镖」是7X24小时在线的社区治理机器人，如果出现言语侮辱等，平台会自动拦截有害信息。'],
              rule: ['1、开通会员后，可在MENU-我的会员权益-健康保镖查看。','2、会员过期后，功能失效。'],
              limit: []
            },
            {
              id: 14,
              name: '免费2G储存空间',
              content: '开通百工宝会员后即获得',
              introduce: ['1、面向用户：所有百工宝会员','2、服务介绍：个人储存空间达到上限怎么办？会员专享储存空间上限提高特权，让你解放空间！'],
              rule: ['1、开通会员后，立即免费获取2G储存空间。','2、会员过期后，功能失效。'],
              limit: []
            },
            {
              id: 15,
              name: '免费下载资料',
              content: '百工宝知识仓库资料限免',
              introduce: ['1、面向用户：所有百工宝会员','2、服务介绍：百工宝会员可免费下载知识仓库付费好文。'],
              rule: ['1、开通会员后，可在【知识仓库】免费下载10次。','2、会员过期后，功能失效。'],
              limit: []
            }
          ]
        }
      ],
    }
  },
  props: {
    onChange: {
      type:Function,
      default() {
        return function(){

        }
      }
    }
  },
  computed:{
    user() {
      return this.$store.state.user
    },
  },
  created(){
  },
  mounted() {
    this.selectData = this.navList[0].list[0]
  },
  watch: {
  },
  methods:{
    // 选择菜单
    selectOne(e) {
      this.selectData = e
    },
  },
}
</script>

<style scoped>
.member-equity {
  width: 100%;
}

.member-equity .body-box {
  padding: 20px 30px;
  background-color: #FFFFFF;
  margin-bottom: 10px;
  overflow: hidden;
}

.member-equity .body-left {
  width: 180px;
  padding: 20px 10px;
  float: left;
}

.member-equity .body-right {
  width: 1160px;
  padding: 30px 40px;
  float: right;
}

.member-equity .body-left .title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

.member-equity .body-left .nav-name {
  font-size: 18px;
  margin-left: 20px;
  margin-bottom: 18px;
}

.member-equity .body-right .top-btn {
  width: 190px;
  font-size: 18px;
  color: #351702;
  line-height: 42px;
  text-align: center;
  border-radius: 30px;
  background: linear-gradient( 270deg, #F9DCB3 0%, #F8C989 100%);
}

/deep/ .cell {
  font-size: 16px; /* 调整为你想要的字体大小 */
}
</style>
