<template>
    <div class="organ-renewal" style="overflow: hidden; padding-bottom: 100px; margin-left: 10px">
        <div style="font-size: 20px;margin:0 0 20px 0; font-weight: 600;color: #BBBBBB">——————————————— 当前账户详情 ——————————————</div>
        <div style="margin-bottom: 20px">
            <div style="margin-bottom: 20px">
                <span style="margin-right: 30px; font-size: 18px; font-weight: 600">当前子账号使用情况：</span>
                <span style="margin-right: 30px; color: #5BC130;"><i class="el-icon-circle-check"></i> 已激活</span>
                <span style="margin-right: 30px; color: #666666;"><i class="el-icon-circle-close"></i> 未激活</span>
                <span style="margin-right: 30px; color: #FF9800"><i class="el-icon-warning-outline"></i> 已冻结</span>
            </div>

            <div style="display: flex; flex-wrap: wrap">
                <div v-for="(item, index) in subList" :key="index">
                    <div v-if="item.info.status === 0" class="btn-type" style="color: #FFFFFF; background-color: #5BC130">{{item.nickName}} <i class="el-icon-circle-check" style="margin-left: 6px"></i></div>
                    <div v-if="item.info.status === 1" class="btn-type" style="background-color: #E8E8E8">{{item.nickName}} <i class="el-icon-circle-close" style="margin-left: 6px"></i></div>
                    <div v-if="item.info.status === 2" class="btn-type" style="color: #FF9800; border: 1px #FF9800 solid">{{item.nickName}} <i class="el-icon-warning-outline" style="margin-left: 6px"></i></div>
                </div>
            </div>
        </div>
        <div style="display: flex; justify-content: space-between">
            <div style="font-size: 18px; line-height: 40px">
                <div><span style="font-weight: 600">机构：</span><span style="color: #999999">已购<span style="color: #EE3F4D">{{userWalletInfo.subaccount}}</span>个子账号</span></div>
                <div><span style="font-weight: 600">购买日期：</span><span style="color: #999999">{{userWalletInfo.subStartTime}}</span></div>
                <div v-if="userWalletInfo.isSub == 1"><span style="font-weight: 600">子账号截止日期：</span><span style="color: #1479FF">{{userWalletInfo.subEndTime}}</span></div>
                <div v-if="userWalletInfo.isSub == 0"><span style="font-weight: 600;color: red;">子账号到期日期：</span><span style="color: #1479FF">{{userWalletInfo.subEndTime}}</span></div>
                <div v-if="userWalletInfo.isSub == -1"><span style="font-weight: 600;color: #a6a6a6;">子账号已到期：</span><span style="color: #a6a6a6">{{userWalletInfo.subEndTime}}</span></div>
            </div>
            <div style="display: flex">
                <div @click="showReWindow('6')" class="pay-btn hand op" style="color: #1479FF; border: 1px #1479FF solid; margin-top: 60px; margin-right: 10px">增加子账号</div>
                <div @click="showReWindow('7')" class="pay-btn hand op" style="color: #EE3F4D; border: 1px #EE3F4D solid; margin-top: 60px">子账号续费</div>
            </div>
        </div>
        <hr style="color: #CCCCCC; margin: 20px 0">
        <div style="margin-bottom: 30px">
          <SpaceBar v-if="JSON.stringify(sizeInfo) !== '{}'" :spaceSize="sizeInfo"></SpaceBar>
        </div>
        <div style="display: flex; justify-content: space-between">
            <div style="font-size: 18px; line-height: 40px">
                <div><span style="font-weight: 600">空间容量：</span><span style="color: #999999">已购<span style="color: #EE3F4D">{{userWalletInfo.spaceSize}}GB</span></span></div>
                <div><span style="font-weight: 600">购买日期：</span><span style="color: #999999">{{userWalletInfo.spaceStartTime}}</span></div>
                <div v-if="userWalletInfo.isSpace == 1"><span style="font-weight: 600">空间截止日期：</span><span style="color: #1479FF">{{userWalletInfo.spaceEndTime}}</span></div>
                <div v-if="userWalletInfo.isSpace == 0"><span style="font-weight: 600;color: red;">空间到期日期：</span><span style="color: #1479FF">{{userWalletInfo.spaceEndTime}}</span></div>
                <div v-if="userWalletInfo.isSpace == -1"><span style="font-weight: 600;color: #a6a6a6;">空间已过期：</span><span style="color: #a6a6a6">{{userWalletInfo.spaceEndTime}}</span></div>
            </div>
            <div style="display: flex">
                <div @click="showReWindow('4')" class="pay-btn hand op" style="color: #EE3F4D; border: 1px #EE3F4D solid; margin-top: 60px;margin-right: 10px;">空间续费</div>
                <div @click="showReWindow('5')" class="pay-btn hand op" style="color: #1479FF; border: 1px #1479FF solid; margin-top: 60px; ">扩容空间</div>
            </div>
        </div>

        <!--续费弹窗-->
        <el-dialog :visible.sync="reShow" width="39%" :show-close="false" class="event-form" :close-on-click-modal="false">
            <div class="top">
                {{btnType}}
                <img src="@/assets/imgs/index/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="reShow = false;payType = 0">
            </div>
            <div style="padding: 20px 40px; display: flex; flex-wrap: wrap; justify-content: space-between">
                <AddSub v-if="payType == 6"  :type="1" @childEvent="handleChildEvent" ref="childSub"></AddSub>
                <SubRenewal v-if="payType == 7"  :type="1" :subaccount="userWalletInfo.subaccount" @childEvent="handleChildEvent"></SubRenewal>
                <EnlargeSpace v-if="payType == 5 || payType == 4" :payType="payType" :type="1" @childEvent="handleChildEvent" ref="childSpace"></EnlargeSpace>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import SpaceBar from "@/components/common/spaceBar.vue";
    import EnlargeSpace from "../EnlargeSpace";
    import AddSub from "./addSub";
    import SubRenewal from "./SubRenewal";
    export default {
        name: "OrganRenewal",
        components: {SubRenewal, AddSub, EnlargeSpace, SpaceBar},
        data() {
            return {
                reShow: false,
                payType: '',
                btnType: '',
                subList: [],
                showMessage:false,
                userWalletInfo:this.info,
                sizeInfo: {}
            }
        },
        computed: {
            user() {
                return this.$store.state.user;
            },
        },
        props: {
            info: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        mounted() {
            var that = this;
            this.getSubList();
            this.getMySpaceSize()
            // 先销毁事件，防止重复触发
            this.$EventBus.$EventBus.$off("payMsg");
            //支付回调广播
            this.$EventBus.$on("payMsg", (data) => {
                this.$nextTick(() => {
                    if(data.code == 100){
                        console.log("organRenewal");
                        that.reShow = false;
                        that.payType = 0;
                        setTimeout(function (){
                            that.getSubList();
                            that.getUserWalletInfo();
                        },1000)
                    }
                });
            });
        },

        methods: {
            // 获取空间大小
            getMySpaceSize() {
              this.newApi.getMySpaceSize().then(res => {
                this.sizeInfo = res.data
              })
            },

            //获取钱包的用户信息
            getUserWalletInfo() {
                var that = this;
                that.newApi.getUserWalletInfo().then(res => {
                    if (res.isSuccess == 1) {
                        that.userWalletInfo = res.data;
                    }
                });
            },

            //子组件控制弹窗显示隐藏
            handleChildEvent(){
                var that = this;
                that.reShow = false;
                this.showMessage = true;
            },
            // 获取子账号列表
            getSubList() {
                this.newApi.getSubAccountList().then(res => {
                    this.subList = [];
                    for (const item of res.data.admin) {
                        this.subList.push(item)
                    }
                    for (const item of res.data.ordinary) {
                        this.subList.push(item)
                    }
                })
            },

            // 操作窗口
            showReWindow(payType) {
                this.reShow = true;
                this.payType = payType;
                if (payType === '6') {
                    this.btnType = '增加子账号'
                }
                if (payType === '7') {
                    this.btnType = '子账号续费'
                }
                if (payType === '4') {
                    this.btnType = '空间续费'
                }
                if (payType === '5') {
                    this.btnType = '扩容空间'
                }
            },

        }
    }
</script>

<style scoped>
    .organ-renewal .pay-btn {
        font-size: 18px;
        font-weight: 600;
        width: 150px;
        height: 24px;
        padding: 12px 0;
        text-align: center;
    }

    .event-form .top {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        color: #444444;
        border: 1px solid #CECFD0;
        height: 60px;
        box-sizing: border-box;
        line-height: 60px;
    }

    .organ-renewal .btn-type {
        height: 38px;
        line-height: 38px;
        padding: 0 10px;
        margin: 0 10px 10px 0;
        font-size: 18px;
        border-radius: 5px;
        text-align: center;
    }

    /deep/ .event-form .el-dialog__header {
        padding: 0;
    }

    /deep/ .event-form .el-dialog__body {
        padding: 0;
    }
</style>
